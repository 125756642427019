<template>
  <component
    :is="tag"
    ref="selectWrapperRef"
    v-mdb-click-outside="close"
    :class="className"
    v-bind="$attrs"
    @click="toggle"
    @keydown.enter.prevent="handleEnterAndSpace"
    @keydown.esc="close"
    @keydown.down.prevent.exact="handleArrowDown"
    @keydown.up.prevent.exact="handleArrowUp"
    @keydown.tab="handleTab"
    @keydown.alt.down="open"
    @keydown.alt.up="close"
    @keydown.space.prevent="handleEnterAndSpace"
    @keydown="onKeyDown"
  >
    <MDBInput
      v-model="inputValue"
      labelClass="select-label"
      class="select-input"
      :class="inputClassName"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :size="size"
      :aria-disabled="disabled"
      :aria-expanded="isDropdownActive"
      :aria-required="isValidated && required"
      :role="filter ? 'combobox' : 'listbox'"
      :tabindex="tabindex"
      aria-haspopup="true"
      readonly
      :white="white"
      :autocomplete="autocomplete"
    >
      <div class="valid-feedback">
        {{ validFeedback }}
      </div>
      <div class="invalid-feedback">
        {{ invalidFeedback }}
      </div>
      <span
        v-if="inputValue && clearButton"
        class="select-clear-btn"
        tabindex="0"
        @click.stop="clear"
        @keydown.enter.stop="clear"
        >✕</span
      >
      <span v-if="arrow" class="select-arrow"></span>
    </MDBInput>
  </component>
  <div
    v-if="isDropdownActive"
    :id="dropdownId"
    ref="dropdownRef"
    class="select-dropdown-container"
    :style="selectDropdownContainerStyle"
  >
    <div
      class="select-dropdown"
      :class="isPopperActive && 'open'"
      :tabindex="dropdownTabindex"
    >
      <div v-if="filter" ref="searchWrapperRef" class="input-group" @click.stop>
        <input
          ref="searchRef"
          class="form-control select-filter-input"
          :placeholder="searchPlaceholder"
          role="searchbox"
          type="text"
          @input="handleFilter"
          @keydown.enter.prevent="handleEnterAndSpace"
          @keydown.esc="close"
          @keydown.down.prevent="handleArrowDown"
          @keydown.up.prevent="handleArrowUp"
          @keydown.tab="handleTab"
          @touchstart.stop
        />
      </div>
      <div
        ref="selectOptionsWrapperRef"
        class="select-options-wrapper"
        :style="selectOptionsWrapperStyle"
        @touchstart.stop
      >
        <div
          v-if="filter && filteredOptions.length === 0"
          class="select-no-results"
          :style="{ height: `${optionHeight}px` }"
        >
          {{ noResultsText }}
        </div>
        <div class="select-options-list">
          <div
            v-if="multiple && selectAll && search === ''"
            class="select-option"
            :style="{ height: `${optionHeight}px` }"
            :class="[
              activeOptionKey === -1 && 'active',
              areAllOptionsChecked && 'selected',
              isOptgroup ? 'select-option-group' : null,
            ]"
            @click.stop="toggleSelectAll"
          >
            <span class="select-option-text">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="areAllOptionsChecked"
                  tabindex="-1"
                />
                {{ selectAllLabel }}
              </div>
            </span>
          </div>
          <div
            v-for="(option, key) in filteredOptions"
            class="select-option"
            :class="[
              option.disabled && 'disabled',
              activeOptionKey === key && 'active',
              option.selected && 'selected',
              isOptgroup && !option.optgroup ? 'select-option-group' : null,
            ]"
            :style="{ height: `${optionHeight}px` }"
            @click.stop="handleOptionClick(option)"
            :key="key"
            role="option"
            :aria-selected="option.selected"
            :aria-disabled="option.disabled || false"
            :hidden="option.hidden"
          >
            <span v-if="multiple" class="select-option-text">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="option.selected"
                  tabindex="-1"
                  :disabled="option.disabled || false"
                />
                {{ option.text }}
                <span
                  v-if="option.secondaryText"
                  class="select-option-secondary-text"
                  >{{ option.secondaryText }}</span
                >
              </div>
            </span>
            <span v-else class="select-option-text">
              {{ option.text }}
              <span
                v-if="option.secondaryText"
                class="select-option-secondary-text"
                >{{ option.secondaryText }}</span
              >
            </span>
            <span v-if="option.icon" class="select-option-icon-container"
              ><img
                class="select-option-icon rounded-circle"
                :src="option.icon"
                alt="select-icon"
            /></span>
          </div>
        </div>
      </div>
      <div
        v-if="$slots.default"
        class="select-custom-content"
        @click.stop
        @touchstart.stop
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBSelect",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, ref, watch, onBeforeMount, nextTick, PropType } from "vue";
import MDBInput from "../../../../src/components/free/forms/MDBInput.vue";
import MDBPopper from "../../../../src/components/utils/MDBPopper";
import { getUID } from "../../../../src/components/utils/getUID";
import vMdbClickOutside from "../../../../src/directives/free/mdbClickOutside";

export interface Option {
  text: string | number;
  value?: string | number;
  mdbKey?: number;
  selected?: boolean;
  optgroup?: boolean;
  disabled?: boolean;
  secondaryText?: string;
  icon?: string;
  hidden?: boolean;
}

const props = defineProps({
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  selected: [String, Array, Number] as PropType<
    string | number | { [props: string]: string | number | boolean }[]
  >,
  preselect: {
    type: Boolean,
    default: true,
  },
  label: String,
  placeholder: String,
  disabled: Boolean,
  optionHeight: {
    type: Number,
    default: 38,
  },
  visibleOptions: {
    type: Number,
    default: 5,
  },
  optionsSelectedLabel: {
    type: String,
    default: "options selected",
  },
  displayedLabels: {
    type: Number,
    default: 5,
  },
  selectAll: {
    type: Boolean,
    default: true,
  },
  selectAllLabel: {
    type: String,
    default: "Select all",
  },
  required: Boolean,
  size: String,
  clearButton: Boolean,
  multiple: Boolean,
  isValidated: Boolean,
  isValid: Boolean,
  validFeedback: String,
  invalidFeedback: String,
  filter: Boolean,
  searchPlaceholder: {
    type: String,
    default: "Search...",
  },
  noResultsText: {
    type: String,
    default: "No results",
  },
  filterDebounce: {
    type: Number,
    default: 300,
  },
  tag: {
    type: String,
    default: "div",
  },
  arrow: {
    type: Boolean,
    default: true,
  },
  autoSelect: Boolean,
  tabindex: {
    type: Number,
    default: 0,
  },
  white: {
    type: Boolean,
    default: false,
  },
  autocomplete: String,
  filterFn: Function as PropType<
    (data: Option[], searchValue: string) => Option[]
  >,
});

const emit = defineEmits([
  "update:options",
  "update:selected",
  "update:modelValue",
  "change",
  "open",
  "opened",
  "close",
  "closed",
  "clear",
]);

// Class & styles ------------------------
const className = computed(() => {
  return [
    "select-wrapper",
    isSelectValidated.value && isSelectValid.value ? "is-valid" : "",
    isSelectValidated.value && !isSelectValid.value ? "is-invalid" : "",
  ];
});
const inputClassName = computed(() => {
  return [
    isPopperActive.value && "focused",
    (modelSelected.value && !inputValue.value) || isPopperActive.value
      ? "active"
      : null,
  ];
});
const selectDropdownContainerStyle = computed(() => {
  return {
    width: dropdownWidth.value,
  };
});
const selectOptionsWrapperStyle = computed(() => {
  return {
    maxHeight: `${props.visibleOptions * props.optionHeight}px`,
  };
});

// Config ------------------------
const selectWrapperRef = ref(null);
const dropdownRef = ref(null);
const selectOptionsWrapperRef = ref(null);
const dropdownId = getUID("MDBSelectDropdown-");
const dropdownWidth = ref("200px");
const isDropdownActive = ref(false);
const activeOptionKey = ref(null);
let wasInitiated = false;
const popperConfig = {
  placement: "bottom-start",
  eventsEnabled: true,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 1],
      },
    },
  ],
};

// Data ------------------------
const modelOptions = ref(props.options as Option[]);
const filteredOptions = ref(modelOptions.value);
const modelSelected = ref(props.selected);
const isOptgroup = ref(modelOptions.value.some((option) => option.optgroup));
modelOptions.value.map((option: Option, key) => (option.mdbKey = key));
let lookupLastTime = 0;
let lookupPrefix = "";
const dropdownTabindex = ref(0);

// Popper ------------------------
const { setPopper, isPopperActive, closePopper, openPopper } = MDBPopper();

// Public methods ------------------------
const toggle = () => {
  if (isPopperActive.value) {
    close();
  } else {
    open();
  }
};

const close = () => {
  if (!isPopperActive.value) {
    return;
  }

  dropdownTabindex.value = -1;
  closePopper();
  emit("close");
  setActiveOptionKey();
  setTimeout(() => {
    isDropdownActive.value = false;
    search.value = "";
    filteredOptions.value = modelOptions.value;
    emit("closed");
  }, 300);
};

const open = () => {
  if (props.disabled || isPopperActive.value) {
    return;
  }

  dropdownTabindex.value = 0;
  isDropdownActive.value = true;
  setActiveOptionKey();
  nextTick(() => {
    openDropdown();
    emit("opened");
  });

  if (props.filter) {
    setTimeout(() => {
      initSearch();
      scrollToInput();
    }, 100);
  }
};

const setOption = (key: number) => {
  if (props.multiple) {
    if (key === -1) {
      return toggleSelectAll();
    }
    if (!modelOptions.value[key].disabled) {
      modelOptions.value[key].selected = !modelOptions.value[key].selected;
    }
  } else {
    modelOptions.value.forEach((option) => {
      option.selected = false;
    });
    modelOptions.value[key].selected = true;
  }
};

const clear = () => {
  modelOptions.value.forEach((option: Option) => {
    option.selected = false;
  });
  activeOptionKey.value = null;
  emit("clear");
};

const setValue = (request: number[] | number) => {
  clear();

  if (props.multiple && Array.isArray(request)) {
    request.forEach((val) => {
      const selectedKey = modelOptions.value.findIndex(
        (option) => option.value === val
      );
      if (selectedKey >= 0) {
        setOption(selectedKey);
      }
    });
  } else {
    const selectedKey = modelOptions.value.findIndex(
      (option: Option) => option.value === request
    );

    if (selectedKey >= 0) {
      setOption(selectedKey);
      close();
    }
  }
};

const toggleSelectAll = () => {
  const areAllOptionsSelected = areAllOptionsChecked.value;
  modelOptions.value.forEach((option) => {
    !option.disabled && (option.selected = !areAllOptionsSelected);
  });
};

// Private methods ------------------------
const emitChangeEvents = () => {
  emit("update:selected", modelSelected.value);
  if (wasInitiated) {
    emit("change");
  } else {
    wasInitiated = true;
  }
};

const setActiveOptionKey = () => {
  if (selectedOptions.value[0]) {
    activeOptionKey.value = filteredOptions.value.findIndex(
      (option) => option === selectedOptions.value[0]
    );

    if (props.multiple && props.selectAll && areAllOptionsChecked.value) {
      activeOptionKey.value = -1;
    }
  }
};

const openDropdown = () => {
  if (!dropdownRef.value) {
    return;
  }

  setPopper(selectWrapperRef.value, dropdownRef.value, popperConfig);
  openPopper();
  dropdownWidth.value = `${selectWrapperRef.value.offsetWidth}px`;

  nextTick(() => scrollBottomToOption());

  emit("open");
};

const initSearch = () => {
  if (searchRef.value) {
    searchRef.value.focus();
    searchHeight = searchWrapperRef.value.offsetHeight;
  }
};

const getScrollParent = (node: HTMLElement) => {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
};

const scrollToInput = () => {
  if (!window) {
    return;
  }

  const scrollableParent = getScrollParent(selectWrapperRef.value);

  if (window.innerWidth < 992 && scrollableParent) {
    const selectOffsetTop = selectWrapperRef.value.offsetTop;
    scrollableParent.scrollTo({
      top: selectOffsetTop - 20,
      behavior: "smooth",
    });
  }
};

const setFirstNotDisabledOption = () => {
  if (
    !props.multiple &&
    props.preselect &&
    modelOptions.value.filter((option) => option.selected === true).length === 0
  ) {
    const firstNotDisabledOption = modelOptions.value.findIndex(
      (option) => option.disabled !== true
    );
    setOption(firstNotDisabledOption);
  }

  if (!props.preselect) {
    wasInitiated = true;
  }
};

const handleOptionClick = (option: Option) => {
  if (option.disabled) {
    return;
  }

  setOption(option.mdbKey);
  if (!props.multiple) {
    close();
  }
};

const setDefaults = () => {
  setFirstNotDisabledOption();
  setActiveOptionKey();
};

// Getters
const selectedOptions = computed(() => {
  /* eslint-disable */
  if (props.multiple) {
    modelSelected.value = modelOptions.value
      .filter((option) => option.selected === true)
      .map((option) => option.value)
      .join(",");
  } else if (
    modelOptions.value.filter((value) => value.selected === true).length > 0
  ) {
    modelSelected.value = modelOptions.value.filter(
      (value) => value.selected === true
    )[0].value;
  } else {
    modelSelected.value = "";
    return "";
  }

  return modelOptions.value.filter((value) => value.selected === true);
  /* eslint-enable */
});

const inputValue = computed(() => {
  if (!selectedOptions.value || selectedOptions.value.length === 0) {
    return "";
  } else if (selectedOptions.value.length === 1) {
    return selectedOptions.value[0].text;
  } else if (props.multiple) {
    if (
      props.displayedLabels !== -1 &&
      selectedOptions.value.length > props.displayedLabels
    ) {
      return `${selectedOptions.value.length} ${props.optionsSelectedLabel}`;
    } else {
      return selectedOptions.value
        .map((selectedOption) => selectedOption.text)
        .join(", ");
    }
  }
  return "";
});

const optionsNotDisabled = (options: Option[]) => {
  return options.filter((option) => option.disabled !== true);
};

const areAllOptionsChecked = computed(() => {
  if (
    props.multiple &&
    props.selectAll &&
    optionsNotDisabled(selectedOptions.value as Option[]).length ===
      optionsNotDisabled(modelOptions.value).length
  ) {
    return true;
  }
  return false;
});

const activeEl = computed(() => {
  return dropdownRef.value.querySelectorAll(".select-option")[
    activeOptionKey.value
  ];
});

const isSelectAllVisible = computed(() => {
  if (props.multiple && props.selectAll && search.value === "") {
    return true;
  }

  return false;
});

const selectAllOptionHeight = computed(() => {
  if (isSelectAllVisible.value) {
    return props.optionHeight;
  }

  return 0;
});

const activeOptionOffsetTop = computed(() => {
  let offsetTop = 0;

  if (activeEl.value) {
    offsetTop =
      activeEl.value.offsetTop + selectAllOptionHeight.value - searchHeight;
  }

  return offsetTop;
});

const optionListHeight = computed(() => {
  return props.visibleOptions * props.optionHeight;
});

const isActiveOptionVisible = computed(() => {
  if (
    selectOptionsWrapperRef.value &&
    selectOptionsWrapperRef.value.scrollTop < activeOptionOffsetTop.value &&
    selectOptionsWrapperRef.value.scrollTop + optionListHeight.value >
      activeOptionOffsetTop.value
  ) {
    return true;
  }

  return false;
});

// Keyboard accessibility ------------------------
const handleEnterAndSpace = () => {
  if (props.multiple) {
    if (isPopperActive.value && activeOptionKey.value === null) {
      close();
      focusInput();
    } else if (isPopperActive.value && activeOptionKey.value === -1) {
      setOption(-1);
    } else if (isPopperActive.value && activeOptionKey.value !== null) {
      setOption(filteredOptions.value[activeOptionKey.value].mdbKey);
    } else {
      open();
    }
  } else {
    if (isPopperActive.value) {
      if (activeOptionKey.value !== null) {
        setOption(filteredOptions.value[activeOptionKey.value].mdbKey);
      }
      close();
      focusInput();
    } else {
      open();
    }
  }
};

const handleTab = () => {
  if (
    props.autoSelect &&
    !props.multiple &&
    isPopperActive.value &&
    activeOptionKey.value !== null
  ) {
    setOption(filteredOptions.value[activeOptionKey.value].mdbKey);
  }
  close();
};

const onKeyDown = (event: KeyboardEvent) => {
  if (props.disabled && !props.filter) {
    return;
  }

  const lookupThreshold = 1000; // milliseconds
  const now = performance.now();
  if (now - lookupLastTime > lookupThreshold) {
    lookupPrefix = "";
  }
  lookupPrefix += event.key.toLowerCase();
  lookupLastTime = now;

  const index = modelOptions.value.findIndex((item) => {
    const text = item.text.toString();

    return (
      text.toLowerCase().startsWith(lookupPrefix) &&
      !item.disabled &&
      !item.hidden
    );
  });

  if (index !== -1) {
    activeOptionKey.value = index;
    scrollBottomToOption();
  }
};

const focusInput = () => {
  if (props.filter) {
    selectWrapperRef.value.querySelector(".select-input").focus();
  }
};

const handleArrowDown = () => {
  setNextActiveOptionKey();

  if (isDropdownActive.value) {
    scrollBottomToOption();
  }

  if (!props.multiple && !isDropdownActive.value) {
    setOption(activeOptionKey.value);
  } else if (props.multiple && !isDropdownActive.value) {
    open();
  }
};

const handleArrowUp = () => {
  if (activeOptionKey.value === null) {
    return;
  }

  setPrevActiveOptionKey();

  if (isDropdownActive.value) {
    scrollTopToOption();
  }

  if (!props.multiple && !isDropdownActive.value) {
    setOption(activeOptionKey.value);
  } else if (props.multiple && !isDropdownActive.value) {
    open();
  }
};

const setNextActiveOptionKey = () => {
  let nextOptionKey = activeOptionKey.value;

  if (activeOptionKey.value === null && isSelectAllVisible.value) {
    nextOptionKey = -1;
  } else {
    nextOptionKey = filteredOptions.value.findIndex(
      (option, key) =>
        (key > nextOptionKey || nextOptionKey === null) &&
        !option.disabled &&
        !option.hidden
    );

    if (nextOptionKey === -1) {
      return;
    }
  }

  activeOptionKey.value = nextOptionKey;
};

const setPrevActiveOptionKey = () => {
  let prevOptionKey = activeOptionKey.value;

  if (activeOptionKey.value === 0 && isSelectAllVisible.value) {
    prevOptionKey = -1;
  } else {
    prevOptionKey = filteredOptions.value.indexOf(
      filteredOptions.value
        .filter(
          (option, key) =>
            key < prevOptionKey && !option.disabled && !option.hidden
        )
        .pop()
    );

    if (prevOptionKey === -1) {
      return;
    }
  }

  activeOptionKey.value = prevOptionKey;
};

const scrollBottomToOption = () => {
  if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
    const offsetBottom =
      activeOptionOffsetTop.value - optionListHeight.value + props.optionHeight;
    selectOptionsWrapperRef.value.scrollTo(0, offsetBottom);
  }
};

const scrollTopToOption = () => {
  if (!isActiveOptionVisible.value && selectOptionsWrapperRef.value) {
    selectOptionsWrapperRef.value.scrollTo(0, activeOptionOffsetTop.value);
  }
};

// Validation ------------------------
const isSelectValidated = ref(props.isValidated);
const isSelectValid = ref(props.isValid);

// Filtering ------------------------
const searchWrapperRef = ref(null);
const searchRef = ref(null);
const search = ref("");
let filterTimeout: number;
let searchHeight = 0;

const defaultFilterFn = (data: Option[], searchValue: string) =>
  data.filter((option) =>
    (option.text as string).toLowerCase().includes(searchValue.toLowerCase())
  );

const handleFilter = (event: InputEvent) => {
  clearTimeout(filterTimeout);
  filterTimeout = setTimeout(() => {
    activeOptionKey.value = null;
    const target = event.target as HTMLInputElement;
    search.value = target.value;

    const filterFn = props.filterFn ? props.filterFn : defaultFilterFn;

    filteredOptions.value = filterFn(modelOptions.value, search.value);

    closePopper();
    openPopper();
  }, props.filterDebounce);
};

// Hooks ------------------------
onBeforeMount(() => {
  if (modelOptions.value.length > 0) {
    setDefaults();
  }
});
// Watchers ----------------------
watch(
  () => props.options,
  (options: Option[]) => {
    modelOptions.value = options;
    modelOptions.value.map((option, key) => (option.mdbKey = key));
    filteredOptions.value = modelOptions.value;
    if (modelOptions.value.length > 0) {
      setDefaults();
    }
  }
);

watch(
  () => modelSelected.value,
  () => emitChangeEvents()
);

watch(
  () => props.isValidated,
  (value) => (isSelectValidated.value = value)
);

watch(
  () => props.isValid,
  (value) => (isSelectValid.value = value)
);

defineExpose({
  clear,
  close,
  open,
  setValue,
  setOption,
  toggle,
  toggleSelectAll,
});
</script>
