<template>
  <component
    :is="tag"
    ref="formRef"
    :class="className"
    noValidate
    @submit="onSubmit"
  >
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBStepperForm",
};
</script>

<script setup lang="ts">
import { computed, ref, toRefs, inject } from "vue";
import { validateStep } from "./stepper-validation";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

interface HTMLElementWithValidation extends HTMLElement {
  checkValidity?: () => boolean;
}

const props = defineProps({
  tag: {
    type: String,
    default: "form",
  },
});

const { steps, activeStep } = toRefs(inject<State>("state"));
const linear = inject<boolean>("linear");
const setStepAttribute =
  inject<(index: number, attribute: string) => void>("setStepAttribute");

const formRef = ref(null);

const className = computed(() => {
  return ["stepper-form", "needs-validation"];
});

const onSubmit = (e: Event) => {
  const form = e.target as HTMLElementWithValidation;

  if (!form.checkValidity()) {
    e.preventDefault();
    e.stopPropagation();
  }

  steps.value.forEach((el, i) => {
    setStepAttribute(i, "validated");
    validateStep(activeStep.value, steps.value, i, linear, true);
  });
};
</script>
