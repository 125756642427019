interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

function toggleStepClass(
  validatingStepIndex: number,
  action: string,
  className: string,
  steps: Step[]
) {
  // condition to prevent errors if the user has not set any custom classes like active, disabled etc.
  if (className) {
    steps[validatingStepIndex].ref.classList[action](className);
  }
}

export function toggleInvalid(validatingStepIndex: number, steps: Step[]) {
  toggleStepClass(validatingStepIndex, "add", "stepper-invalid", steps);
  toggleStepClass(validatingStepIndex, "remove", "stepper-completed", steps);
  toggleStepClass(validatingStepIndex, "add", "", steps);
  toggleStepClass(validatingStepIndex, "remove", "", steps);
}

export function validateStep(
  validatingStepIndex: number,
  steps: Step[],
  nextStepIndex: number,
  stepperLinear: boolean,
  isLast = false
) {
  const numberOfSteps = steps.length;
  let result = true;
  // prevent any actions if the same step is chosen
  if (!isLast && validatingStepIndex === nextStepIndex) {
    result = false;
  }
  // prevent toggleSteps if next/prev step don't exist
  if (validatingStepIndex >= numberOfSteps || validatingStepIndex < 0) {
    result = false;
  }

  if (stepperLinear) {
    // prevent toggleStep if one of the steps is skipped
    if (!isLast && validatingStepIndex > nextStepIndex - 1) {
      result = false;
    }

    if (
      nextStepIndex > validatingStepIndex ||
      nextStepIndex === numberOfSteps ||
      isLast
    ) {
      const activeStep = steps[validatingStepIndex].ref;

      const requiredElements = Array.prototype.slice.call(
        activeStep.querySelectorAll(
          "input[required]"
        ) as NodeListOf<HTMLInputElement>
      ) as HTMLInputElement[];

      const isValid = requiredElements.every((el) => {
        return el.checkValidity() === true;
      });

      if (!isValid) {
        toggleInvalid(validatingStepIndex, steps);

        result = false;
      }
    }
  }
  return result;
}
