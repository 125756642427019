<template>
  <teleport v-if="appendToBody" to="body">
    <component
      :is="tag"
      :id="uid"
      ref="toastRef"
      class=""
      :class="className"
      :style="[displayStyle, widthStyle, alignmentStyle]"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      :data-mdb-stacking="stacking ? true : null"
      :data-mdb-static="props.static ? true : null"
      v-bind="$attrs"
    >
      <div :class="headerClassName">
        <i v-if="icon" :class="icon" />
        <strong class="me-auto"><slot name="title" /></strong>
        <small><slot name="small" /></small>
        <button
          type="button"
          :class="btnClassName"
          aria-label="Close"
          @click="hide"
        ></button>
      </div>
      <div :class="bodyClassName"><slot /></div>
    </component>
  </teleport>
  <component
    :is="tag"
    v-else
    :id="uid"
    ref="toastRef"
    class=""
    :class="className"
    :style="[displayStyle, widthStyle, alignmentStyle]"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :data-mdb-stacking="stacking ? true : null"
    v-bind="$attrs"
  >
    <div :class="headerClassName">
      <i v-if="icon" :class="icon" />
      <strong class="me-auto"><slot name="title" /></strong>
      <small><slot name="small" /></small>
      <button
        type="button"
        :class="btnClassName"
        aria-label="Close"
        @click="hide"
      ></button>
    </div>
    <div :class="bodyClassName"><slot /></div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBToast",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import type { Ref } from "vue";
import { on, off } from "../../../../src/components/utils/MDBEventHandlers";
import { getUID } from "../../../../src/components/utils/getUID";
import MDBStackableElements from "../../../../src/components/utils/MDBStackableElements";
import { useMotionReduced } from "../../../composables/free/useMotionReduced";

interface StackOptions {
  [props: string]: any;
}
interface IsetStack {
  (
    proxy: Ref<HTMLElement>,
    element: HTMLElement,
    selector: string,
    options: StackOptions
  ): void;
}

interface IcalculateStackingOffset {
  (): any;
}

interface InextStackElements {
  (): HTMLElement[];
}

interface IresetStackingOffset {
  (): void;
}

interface IstackableElements {
  (): {
    el: any;
    rect: any;
  }[];
}

interface StackableElements {
  setStack: IsetStack;
  calculateStackingOffset: IcalculateStackingOffset;
  nextStackElements: InextStackElements;
  resetStackingOffset: IresetStackingOffset;
  stackableElements: IstackableElements;
}

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  id: String,
  modelValue: Boolean,
  offset: {
    type: String,
    default: "10",
  },
  position: {
    type: String,
    default: "top-right",
  },
  width: {
    type: [String, null],
    default: null,
  },
  color: {
    type: String,
  },
  container: String,
  autohide: {
    type: Boolean,
    default: true,
  },
  animation: {
    type: Boolean,
    default: true,
  },
  delay: {
    type: Number,
    default: 5000,
  },
  appendToBody: {
    type: Boolean,
    default: false,
  },
  stacking: {
    type: Boolean,
    default: true,
  },
  text: String,
  static: {
    type: Boolean,
    default: false,
  },
  icon: String,
  toast: String,
  heightAnimation: Boolean,
});

const emit = defineEmits([
  "update:modelValue",
  "show",
  "shown",
  "hide",
  "hidden",
]);

// -------------- Classes and Styles --------------
const className = computed(() => {
  return [
    "toast",
    props.animation && "fade",
    props.animation &&
      props.heightAnimation &&
      `fade-height ${
        props.position.split("-")[0] === "bottom" ? "fade-height-bottom" : ""
      }`,
    "mx-auto",
    props.color && `bg-${props.color}`,
    props.toast && `toast-${props.toast}`,
    toastPositionClasses.value,
    showClass.value && "show",
  ];
});
const toastPositionClasses = computed(() => {
  if (props.static) return;
  return props.container ? "toast-absolute" : "toast-fixed";
});
const headerClassName = computed(() => {
  return [
    "toast-header",
    props.toast && `toast-${props.toast}`,
    props.color && `bg-${props.color}`,
    props.text && `text-${props.text}`,
  ];
});
const btnClassName = computed(() => {
  return ["btn-close", props.text === "white" ? "btn-close-white" : null];
});
const bodyClassName = computed(() => {
  return [
    "toast-body",
    props.text === "white" ? "text-white" : null,
    "text-start",
  ];
});
const widthStyle = computed(() => `width: ${props.width}`);
const alignmentStyle = ref(null);
const displayStyle = ref(null);
const showClass = ref(props.static ? true : false);

const uid = props.id || getUID("MDBToast-");

// -------------- Refs --------------
const toastRef = ref(null);

// -------------- Positioning --------------
const verticalOffset = () => {
  if (!props.stacking || !props.position) return 0;

  return calculateStackingOffset();
};

const getPosition = () => {
  if (!props.position) return null;
  const [y, x] = props.position.split("-");
  return { y, x };
};

const updatePosition = () => {
  const delay =
    props.animation && props.heightAnimation
      ? stackableElements().length * 300
      : 1;

  setTimeout(() => {
    const { y } = getPosition();
    const offsetY = verticalOffset();

    //  quick update vertical position for stack placement
    toastRef.value.style[y] = `${parseInt(offsetY) + parseInt(props.offset)}px`;

    if (props.heightAnimation) {
      toastRef.value.style.transition = ".3s";

      setTimeout(() => {
        toastRef.value.style.transition = null;
      }, 300);
    }
    // update alignmentStyle value
    // without that toastRef.value.style[y] will be overwritten on hide by alignementStyle
    setupAlignment();
  }, delay);
};

const setupAlignment = () => {
  const offsetY = verticalOffset();
  const position = getPosition();

  const oppositeY = position.y === "top" ? "bottom" : "top";
  const oppositeX = position.x === "left" ? "right" : "left";
  if (position.x === "center") {
    alignmentStyle.value = {
      [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
      [oppositeY]: "unset",
      left: "50%",
      transform: "translate(-50%)",
    };
  } else {
    alignmentStyle.value = {
      [position.y]: `${parseInt(offsetY) + parseInt(props.offset)}px`,
      [position.x]: `${props.offset}px`,
      [oppositeY]: "unset",
      [oppositeX]: "unset",
      transform: "unset",
    };
  }
};

watch(
  () => props.position,
  () => setupAlignment()
);

watch(
  () => props.offset,
  () => setupAlignment()
);

// -------------- Stacking --------------
const {
  setStack,
  calculateStackingOffset,
  nextStackElements,
  resetStackingOffset,
  stackableElements,
}: StackableElements = MDBStackableElements();
const observer = ref<MutationObserver>(null);

const setupStacking = () => {
  setStack(toastRef, toastRef.value, ".toast", {
    position: getPosition().y,
    offset: props.offset,
    container: props.container,
    filter: (el: HTMLElement) => {
      return el.dataset.mdbStacking && !el.dataset.mdbStatic;
    },
  });

  observerStacking();
};

// MutationObserver is a workaround for Vue not being able to communicate
const observerStacking = () => {
  observer.value = new MutationObserver((mutations) => {
    for (const m of mutations) {
      const newValue = (m.target as HTMLElement).getAttribute(m.attributeName);
      nextTick(() => {
        onShouldStackUpdate(newValue);
      });
    }
  });

  observer.value.observe(toastRef.value, {
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ["class"],
  });
};

const updateToastStack = () => {
  const nextElements = nextStackElements();
  if (nextElements.length <= 0) {
    return;
  }
  nextElements.forEach((el) => {
    if (el.id !== uid) {
      el.classList.add("should-stack-update");
    }
  });
};

// MutationObserver
// will fire on component class change
const onShouldStackUpdate = (classAttrValue: string) => {
  const classList = classAttrValue.split(" ");
  if (classList.includes("should-stack-update")) {
    updatePosition();
    toastRef.value.classList.remove("should-stack-update");
  }
};

// -------------- Open/Close --------------
const isActive = ref(props.modelValue);
const timeoutValue = ref(null);

watchEffect(() => {
  isActive.value = props.modelValue;
});

const openToast = () => {
  emit("show");
  _clearTimeout();
  setupAlignment();

  displayStyle.value = "display: block";

  const complete = () => {
    emit("shown");
    off(toastRef.value, "transitionend", complete);

    if (props.autohide) {
      timeoutValue.value = setTimeout(hide, props.delay);
    }
  };

  nextTick(() => {
    setTimeout(() => {
      showClass.value = true;
    }, 0);

    if (props.animation && !useMotionReduced()) {
      on(toastRef.value, "transitionend", complete);
    } else {
      complete();
    }
  });
};

const closeToast = () => {
  emit("hide");

  const complete = () => {
    let delay = 0;

    if (props.heightAnimation) {
      delay = 300;
    }

    setTimeout(() => {
      displayStyle.value = "display: none";
      alignmentStyle.value = null;

      emit("hidden");
      off(toastRef.value, "transitionend", complete);

      if (props.stacking) {
        updateToastStack();
      }
    }, delay);
  };

  showClass.value = false;

  if (props.stacking && !props.static) {
    resetStackingOffset();
  }

  if (props.animation && !props.heightAnimation && !useMotionReduced()) {
    on(toastRef.value, "transitionend", complete);
  } else {
    complete();
  }
};

watch(
  () => isActive.value,
  (isActive) => {
    if (isActive) {
      openToast();
    } else {
      closeToast();
    }
  }
);

const show = () => {
  emit("update:modelValue", true);
};

const hide = () => {
  if (props.autohide && !timeoutValue.value) return;
  emit("update:modelValue", false);
};

const _clearTimeout = () => {
  clearTimeout(timeoutValue.value);
  timeoutValue.value = null;
};

// -------------- Lifecycle Hooks --------------
onMounted(() => {
  if (!props.modelValue) {
    displayStyle.value = "display: none";
  }
  if (props.container) {
    const containerEl = document.querySelector(props.container);
    if (!containerEl) return;

    containerEl.classList.add("parent-toast-relative");
  }

  if (props.stacking) {
    setupStacking();
  }
});

onUnmounted(() => {
  _clearTimeout();
  if (observer.value) {
    observer.value.disconnect();
  }
});

defineExpose({
  show,
  hide,
});
</script>
