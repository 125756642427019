<template>
  <div class="datepicker-date-controls">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToDayViewLabel"
      @click.stop="changeView('day')"
    >
      {{ years[0][0].label }} - {{ years[5][years[5].length - 1].label }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevMultiYearLabel"
        @click="
          () => {
            setFocusedDate(focusedDate.subtract(24, 'year'));
            startingYearKey -= 24;
          }
        "
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextMultiYearLabel"
        @click="
          () => {
            setFocusedDate(focusedDate.add(24, 'year'));
            startingYearKey += 24;
          }
        "
      ></button>
    </div>
  </div>

  <div
    ref="datepickerViewRef"
    class="datepicker-view"
    tabindex="0"
    @keydown.up.prevent="handleDecrementYear(4)"
    @keydown.down.prevent="handleIncrementYear(4)"
    @keydown.left.prevent="handleDecrementYear(1)"
    @keydown.right.prevent="handleIncrementYear(1)"
    @keydown.enter="handleEnter"
    @keydown="handleKeydown"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
  >
    <table class="datepicker-table">
      <tbody class="datepicker-table-body">
        <tr v-for="(yearRow, key) in years" :key="key">
          <td
            v-for="(year, keyInYear) in yearRow"
            :key="keyInYear"
            class="datepicker-cell datepicker-large-cell datepicker-year-cell"
            :class="[
              year.current && 'current',
              year.focused && isDatepickerViewFocused && 'focused',
              !year.selectable && 'disabled',
              year.selected && 'selected',
            ]"
            :aria-label="year.label"
            @click.stop="!year.disabled && handleYearClick(year.label)"
          >
            <div class="datepicker-cell-content datepicker-large-cell-content">
              {{ year.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerYearView",
};
</script>

<script setup lang="ts">
import { inject, ref, computed, onMounted } from "vue";
import type { Ref } from "vue";
import dayjs from "dayjs";

const activeDate = inject<Ref<dayjs.Dayjs>>("activeDate");
const selectedDate = inject<Ref<dayjs.Dayjs>>("selectedDate");
const focusedDate = inject<Ref<dayjs.Dayjs>>("focusedDate");
const setFocusedDate = inject<(date: dayjs.Dayjs) => void>("setFocusedDate");

const changeActiveYear = inject<(year: number) => void>("changeActiveYear");
const changeView = inject<(newView: string) => void>("changeView");
const today = inject<dayjs.Dayjs>("today");
const nextMultiYearLabel = inject<string>("nextMultiYearLabel");
const prevMultiYearLabel = inject<string>("prevMultiYearLabel");
const switchToDayViewLabel = inject<string>("switchToDayViewLabel");
const startingYearKey = ref(-5);

const minDate = inject<Ref<string | dayjs.Dayjs>>("minDate");
const maxDate = inject<Ref<string | dayjs.Dayjs>>("maxDate");
const disablePast = inject<boolean>("disablePast");
const disableFuture = inject<boolean>("disableFuture");

const startingYear = computed(() => {
  if (startingYearKey.value < 0) {
    return activeDate.value.subtract(-startingYearKey.value, "year");
  }
  return activeDate.value.add(startingYearKey.value, "year");
});

const years = computed(() => {
  const list = [];

  for (let i = 0; i < 6; i++) {
    list.push([]);
    const rowStartingDate = startingYear.value.add(i + i * 3, "year");
    for (let j = 0; j < 4; j++) {
      const colStartingDate = rowStartingDate.add(j, "year");
      list[i].push({
        current: colStartingDate.year() === today.year(),
        focused: colStartingDate.isSame(focusedDate.value, "year"),
        label: colStartingDate.year(),
        selectable: isYearSelectable(colStartingDate.year()),
        selected: colStartingDate.isSame(selectedDate.value, "year"),
      });
    }
  }

  return list;
});

const isYearSelectable = (year: number) => {
  if (disablePast) {
    return year >= today.year();
  } else if (disableFuture) {
    return year <= today.year();
  } else if (minDate.value && maxDate.value) {
    return (
      year >= (minDate.value as dayjs.Dayjs).year() &&
      year <= (maxDate.value as dayjs.Dayjs).year()
    );
  } else if (minDate.value) {
    return year >= (minDate.value as dayjs.Dayjs).year();
  } else if (maxDate.value) {
    return year <= (maxDate.value as dayjs.Dayjs).year();
  }

  return true;
};

const handleYearClick = (year: number) => {
  changeActiveYear(year);
  changeView("month");
};

// keyboard accessibility -------------------------------------
const handleDecrementYear = inject<(key: number) => void>(
  "handleDecrementYear"
);
const handleIncrementYear = inject<(key: number) => void>(
  "handleIncrementYear"
);
const handleYearHome = inject<() => void>("handleYearHome");
const handleYearEnd = inject<() => void>("handleYearEnd");
const handleYearPageUp = inject<() => void>("handleYearPageUp");
const handleYearPageDown = inject<() => void>("handleYearPageDown");
const datepickerViewRef = ref(null);
const isDatepickerViewFocused = ref(false);

const handleEnter = () => {
  if (!isYearSelectable(focusedDate.value.year())) return;
  changeActiveYear(focusedDate.value.year());
  changeView("month");
};
const handleKeydown = (event: KeyboardEvent) => {
  switch (event.key) {
    case "Home":
      handleYearHome();
      break;
    case "End":
      handleYearEnd();
      break;
    case "PageUp":
      handleYearPageUp();
      break;
    case "PageDown":
      handleYearPageDown();
      break;
    default:
      break;
  }
};
onMounted(() => {
  setTimeout(() => {
    datepickerViewRef.value.focus();
  }, 100);
});
</script>
