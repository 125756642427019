import Chart, {
  ChartTypeRegistry,
  ChartData,
  ChartConfiguration,
} from "chart.js/auto";

import ChartDataLabels from "chartjs-plugin-datalabels";
import chartsDefaultOptions from "./chartsDefaultOptions";
import merge, { Options } from "deepmerge";
import { ref } from "vue";

type DefaultOptions = typeof chartsDefaultOptions;
type ChartOptions = ChartConfiguration["options"];

interface DeepmergeOptions extends Options {
  cloneUnlessOtherwiseSpecified: (item: object, options: object) => object;
}

function generateCharts(
  canvasRef: HTMLCanvasElement | HTMLElement,
  type: keyof ChartTypeRegistry,
  data: ChartData = { datasets: [] },
  options: ChartOptions = {}
) {
  const _data = ref(data);
  const _chart = ref(null);
  const _options = ref<ChartOptions | null>(null);
  const _plugins = ref(null);

  setupCharts();

  function setupCharts() {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
  }

  function mergeOptions(
    options: ChartOptions,
    type: string,
    defaultOptions: DefaultOptions
  ) {
    if (!options) {
      options = {};
    }

    const mergeObjects = (
      target: ChartOptions[],
      source: ChartOptions[],
      options: DeepmergeOptions
    ) => {
      const destination = target.slice();
      source.forEach((item, index) => {
        if (typeof destination[index] === "undefined") {
          destination[index] = options.cloneUnlessOtherwiseSpecified(
            item,
            options
          );
        } else if (options.isMergeableObject(item)) {
          destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    };

    return merge(defaultOptions[type], options, {
      arrayMerge: mergeObjects,
    });
  }

  function addDataLabels() {
    _plugins.value = [ChartDataLabels];
  }

  function renderChart() {
    destroyChart();

    if (canvasRef instanceof HTMLCanvasElement) {
      _chart.value = new Chart(canvasRef.getContext("2d"), {
        type,
        data: _data.value,
        options: _options.value,
        plugins: _plugins.value,
      });
    }
  }

  function updateChart(data: ChartData, options: ChartOptions) {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
    _data.value = data;

    renderChart();
  }

  function destroyChart() {
    if (_chart.value) {
      _chart.value.destroy();
      _chart.value = null;
    }
  }

  return {
    setupCharts,
    renderChart,
    addDataLabels,
    destroyChart,
    updateChart,
  };
}

export default generateCharts;
