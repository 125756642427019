<template>
  <teleport :to="container">
    <transition
      name="timepicker-fade"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <component
        :is="tag"
        v-if="isActive"
        ref="modalRef"
        class="timepicker-modal"
        tabindex="-1"
        role="dialog"
      >
        <div
          ref="wrapperRef"
          v-mdb-focus-trap
          :class="wrapperClass"
          style="opacity: 1"
          @click.self="handleClickOutside"
        >
          <div
            ref="timepickerContainerRef"
            class="d-flex align-items-center justify-content-center flex-column shadow timepicker-container"
          >
            <div
              class="d-flex flex-column timepicker-elements justify-content-around"
            >
              <MDBTimepickerHeader />
              <div v-if="dateTimepicker" class="buttons-container">
                <button
                  type="button"
                  class="datepicker-button-toggle"
                  data-mdb-toggle="datepicker"
                  @click="changePicker('datepicker')"
                >
                  <i class="far fa-calendar datepicker-toggle-icon"></i>
                </button>
                <button
                  type="button"
                  class="timepicker-button-toggle"
                  data-mdb-toggle="timepicker"
                >
                  <i class="far fa-clock fa-sm timepicker-icon"></i>
                </button>
              </div>
              <MDBTimepickerPlate />
            </div>
            <MDBTimepickerFooter />
          </div>
        </div>
      </component>
    </transition>
  </teleport>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerModal",
};
</script>

<script setup lang="ts">
import { computed, inject, ref, watch } from "vue";
import MDBTimepickerFooter from "./MDBTimepickerFooter.vue";
import MDBTimepickerHeader from "./MDBTimepickerHeader.vue";
import MDBTimepickerPlate from "./MDBTimepickerPlate.vue";
import vMdbFocusTrap from "../../../../../src/directives/free/mdbFocusTrap";
import { useScrollbarWidth } from "../../../../../src/components/utils/useScrollbarWidth";

const props = defineProps({
  modelValue: Boolean,
  tag: {
    type: String,
    default: "div",
  },
});
const emit = defineEmits(["cancel", "update:modelValue"]);

// ------------- REFS -------------
const modalRef = ref(null);
const wrapperRef = ref(null);

// ------------- STYLING -------------
const wrapperClass = computed(() => {
  return [
    "timepicker-wrapper h-100 d-flex align-items-center justify-content-center flex-column position-fixed",
  ];
});

// ------------- OPENING/CLOSING METHODS -------------
const { beforeEnter, afterLeave } = useScrollbarWidth();

const isActive = ref(props.modelValue);

const handleClickOutside = () => {
  if (!isActive.value || debounce.value) return;
  isActive.value = false;

  emit("cancel");
  emit("update:modelValue", false);
};

const debounce = ref(true);

watch(
  () => props.modelValue,
  (value) => {
    isActive.value = value;
    setTimeout(() => {
      debounce.value = value;
      setTimeout(() => (debounce.value = !value), 100);
    }, 500);
  }
);

const dateTimepicker = inject("dateTimepicker", null);
const changePicker = inject("changePicker", null);
const container = inject("container", "body");
</script>
