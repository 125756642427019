<template>
  <component :is="tag" v-if="collapse" :class="className" v-bind="$attrs">
    <MDBSideNavLink v-model="isActive" :icon="isArrowIcon">
      <MDBIcon :icon="icon" :iconStyle="iconStyle" :class="iconClass" />
      <span :class="nonSlimClass">{{ title }}</span>
    </MDBSideNavLink>
    <MDBCollapse v-model="isActive" sidenav tag="ul">
      <slot />
    </MDBCollapse>
  </component>

  <component :is="tag" v-else :class="className" v-bind="$attrs">
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBSideNavItem",
};
</script>

<script setup lang="ts">
import { computed, onMounted, provide, inject, ref, watch } from "vue";
import MDBCollapse from "../../free/components/MDBCollapse.vue";
import MDBIcon from "../../free/content-styles/MDBIcon.vue";
import MDBSideNavLink from "./MDBSideNavLink.vue";

const props = defineProps({
  tag: {
    type: String,
    default: "li",
  },
  collapse: {
    type: Boolean,
    default: false,
  },
  show: {
    type: Boolean,
    default: false,
  },
  classes: String,
  icon: String,
  iconStyle: {
    type: String,
    default: "fas",
  },
  iconClass: {
    type: String,
    default: "me-3",
  },
  title: String,
  hasArrow: {
    type: Boolean,
    default: true,
  },
  nonSlim: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update:show"]);

const slimCollapsed = inject("slimCollapsed", ref(true));

const className = computed(() => {
  return ["sidenav-item", props.classes];
});

const nonSlimClass = computed(() => {
  return [props.nonSlim && "sidenav-non-slim"];
});

watch(
  () => [props.nonSlim, props.show],
  ([currentSlim, currentShow]) => {
    if (currentSlim && !currentShow && slimCollapsed.value) {
      setTimeout(() => {
        spanArray.forEach((element) => {
          element.style.display = "none";
        });
      }, 300);
    } else if (!currentSlim || (currentSlim && currentShow)) {
      spanArray.forEach((element) => {
        element.style.display = "unset";
      });
    }
  }
);

const isArrowIcon = computed(() => {
  return props.hasArrow ? "angle-down" : "";
});

const hasCollapsibleContent = ref(false);
provide("hasCollapsibleContent", hasCollapsibleContent);

let spanArray: HTMLElement[];
onMounted(() => {
  spanArray = [
    ...(document.querySelectorAll(
      ".sidenav-non-slim"
    ) as NodeListOf<HTMLElement>),
  ];

  if (props.nonSlim) {
    spanArray.forEach((element) => {
      element.style.display = "none";
    });
  }

  if (props.collapse) {
    hasCollapsibleContent.value = true;
  }
});

const isActive = ref(props.show);
watch(
  () => props.show,
  (cur) => (isActive.value = cur)
);
</script>
