<template>
  <MDBInput
    :id="uid"
    ref="inputRef"
    v-model="inputValue"
    :label="label"
    :wrapperClass="inputWrapperClass"
    v-bind="$attrs"
    aria-haspopup="dialog"
    :invalidFeedback="invalidFeedback ? invalidFeedback : invalidLabel"
    :validFeedback="validLabel || validFeedback"
    :isValid="valid"
    :isValidated="validated"
    :title="validationTitle"
    @click="handleSelfOpen"
    @input="handleInput"
  >
    <button
      v-if="icon"
      ref="btnRef"
      type="button"
      class="timepicker-toggle-button"
      aria-haspopup="dialog"
      @click="togglePicker"
    >
      <i :class="customIconClass" /></button
  ></MDBInput>
  <teleport v-if="inline" :to="container">
    <transition name="timepicker-fade">
      <MDBTimepickerInline
        v-if="isActive"
        ref="modalRef"
        @cancel="handleCancelClick"
      />
    </transition>
  </teleport>
  <MDBTimepickerModal
    v-else
    ref="modalRef"
    v-model="isActive"
    @cancel="handleCancelClick"
  />
</template>

<script lang="ts">
export default {
  name: "MDBTimepicker",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  provide,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import MDBInput from "../../../../../src/components/free/forms/MDBInput.vue";
import MDBTimepickerModal from "./MDBTimepickerModal.vue";
import MDBTimepickerInline from "./MDBTimepickerInline.vue";
import { getUID } from "../../../../../src/components/utils/getUID";
import { on, off } from "../../../../../src/components/utils/MDBEventHandlers";

const props = defineProps({
  modelValue: [String, Date],
  label: String,
  id: String,
  icon: {
    type: Boolean,
    default: true,
  },
  iconClass: {
    type: String,
    default: "far fa-clock fa-sm",
  },
  selfOpen: Boolean,
  inline: {
    type: Boolean,
    default: false,
  },
  hoursFormat: {
    type: Number,
    default: 12,
    validator: (value: number) => value === 24 || value === 12,
  },
  min: [Number, String],
  max: [Number, String],
  increment: {
    type: Number,
    default: 1,
    validator: (value: number) => {
      return [1, 5, 10, 15, 30].indexOf(value) > -1;
    },
  },
  amLabel: {
    type: String,
    default: "AM",
  },
  pmLabel: {
    type: String,
    default: "PM",
  },
  okLabel: {
    type: String,
    default: "ok",
  },
  clearLabel: {
    type: String,
    default: "clear",
  },
  cancelLabel: {
    type: String,
    default: "cancel",
  },
  invalidLabel: {
    type: String,
    default: "Invalid date format",
  },
  invalidFeedback: String,
  validLabel: String,
  validFeedback: String,
  isValid: Boolean,
  isValidated: Boolean,
  disablePast: {
    type: Boolean,
    default: false,
  },
  disableFuture: {
    type: Boolean,
    default: false,
  },
  container: {
    type: String,
    default: "body",
  },
  ripple: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "update:isValid",
  "update:isValidated",
  "open",
  "close",
]);

// ------------- REFS -------------
const inputRef = ref(null);
const modalRef = ref(null);
const btnRef = ref(null);

const uid = props.id || getUID("MDBInput-");
provide("inputId", uid);

// ------------- STYLING -------------
const inputWrapperClass = computed(() => {
  return "timepicker";
});
const customIconClass = computed(() => {
  return ["timepicker-icon", props.iconClass];
});

// ------------- PROPS VARIABLES -------------
provide("hoursFormat", props.hoursFormat);
provide("amLabel", props.amLabel);
provide("pmLabel", props.pmLabel);
provide("okLabel", props.okLabel);
provide("clearLabel", props.clearLabel);
provide("cancelLabel", props.cancelLabel);
provide("inline", props.inline);
provide("increment", props.increment);
provide("min", props.min);
provide("max", props.max);
provide("container", props.container);
provide("ripple", props.ripple);

// ------------- STATE MANAGEMENT -------------
const inputValue = ref(props.modelValue);

const modelValueProp = toRefs(props).modelValue;
watch(
  () => modelValueProp.value,
  (cur) => {
    if (cur && cur !== inputValue.value) {
      inputValue.value = cur;
      formatToAmPm(cur);
    } else if (cur === "") {
      handleClearClick();
    }
  }
);

const hours = ref(12);
const minutes = ref(0);
const unitsMode = ref("hours");
const dayTime = ref("am");
const currentDate = new Date().toLocaleTimeString("en-US");

const computedMinutes = computed(() => {
  const minutesValue =
    typeof minutes.value === "string" ? parseInt(minutes.value) : minutes.value;
  return minutesValue < 10 ? `0${minutesValue}` : minutesValue;
});
const computedHours = computed(() => {
  if (hours.value < 10) {
    return hours.value.toString().startsWith("0")
      ? hours.value.toString()
      : hours.value === 0
      ? `00`
      : `0${hours.value}`;
  } else {
    return hours.value === 24 ? "00" : hours.value;
  }
});

const computedMinValue = computed(() => {
  if (props.min === undefined) {
    return 0;
  }
  const min = props.min as string;

  if (props.hoursFormat === 24) {
    return getMinutesValue(getTimeUnits(min));
  }
  // needed for situation when 12h format and no dayTime is passed
  // will create range for both am and pm values
  return getMinutesValue(getTimeUnits(min, dayTime.value));
});
const computedMaxValue = computed(() => {
  if (props.max === undefined) {
    return 24 * 60;
  }
  const max = props.max as string;

  if (props.hoursFormat === 24) {
    return getMinutesValue(getTimeUnits(max));
  }
  // needed for situation when 12h format and no dayTime is passed
  // will create range for both am and pm values
  return getMinutesValue(getTimeUnits(max, dayTime.value));
});
const computedDisablePropsValue = computed(() => {
  if (props.disablePast && props.disableFuture === undefined) {
    return 0;
  }

  if (props.hoursFormat === 24) {
    return getMinutesValue(getTimeUnits(currentDate));
  }
  // needed for situation when 12h format and no dayTime is passed
  // will create range for both am and pm values
  return getMinutesValue(getTimeUnits(currentDate, dayTime.value));
});

const changeDayTime = (value: string) => {
  if (!value) return;
  dayTime.value = value.toLowerCase();
};

const chageUnitsMode = (value: string) => {
  if (!value) return;
  unitsMode.value = value.toLowerCase();
};

const updateHoursValue = (value: number) => {
  hours.value = value;
};

const incrementHoursValue = (
  delta: number,
  currentValue: number = hours.value,
  recursion: undefined | boolean = undefined
) => {
  if (unitsMode.value !== "hours") {
    chageUnitsMode("hours");
  }

  const valueToPass =
    typeof currentValue === "string" ? parseInt(currentValue) : currentValue;

  const isCurrentInRange = allowedHours(valueToPass);

  const incrementedValue = valueToPass + delta;
  let result =
    incrementedValue <= 0 || incrementedValue === 24
      ? props.hoursFormat
      : incrementedValue > props.hoursFormat
      ? 1
      : incrementedValue;

  const isIncrementedInRange = allowedHours(result);

  if (isCurrentInRange && !isIncrementedInRange) {
    // prevent from changing to not valid hour
    result = incrementHoursValue(delta, result, true);
  } else if (!isCurrentInRange && !isIncrementedInRange) {
    // if value is between not allowed values
    // e.g after changing dayTime manually inputted value
    if (props.hoursFormat === 24) {
      // find first allowed value in delta direction
      result = incrementHoursValue(delta, result, true);
    } else {
      const activeValues = document.querySelectorAll(
        ".timepicker-time-tips-hours:not(.disabled)"
      );

      const minV = props.min as string;
      const maxV = props.max as string;

      const min = props.min && getTimeUnits(minV);
      const max = props.max && getTimeUnits(maxV);

      if (!activeValues.length) {
        if (delta > 0) {
          result = min ? min.hours : 1;
          minutes.value = allowedMinutes(minutes.value)
            ? minutes.value
            : min
            ? min.minutes
            : max
            ? max.minutes
            : 0;
          changeDayTime(
            (min && min.ampm) || (max && max.ampm) || dayTime.value
          );
        } else if (delta < 0) {
          result = max ? max.hours : props.hoursFormat;
          minutes.value = allowedMinutes(minutes.value)
            ? minutes.value
            : max
            ? max.minutes
            : min
            ? min.minutes
            : 0;
          changeDayTime(
            (max && max.ampm) || (min && min.ampm) || dayTime.value
          );
        }
      } else {
        result = incrementHoursValue(delta, result, true);
      }
    }
  }

  if (recursion) {
    return result;
  }

  hours.value = result;
};

const updateMinutesValue = (value: number) => {
  minutes.value = value;
};

const incrementMinutesValue = (
  delta: number,
  currentValue: number = minutes.value,
  recursion: undefined | boolean = undefined
) => {
  if (unitsMode.value !== "minutes") {
    chageUnitsMode("minutes");
  }

  const valueToPass =
    typeof currentValue === "string" ? parseInt(currentValue) : currentValue;

  const isCurrentInRange = allowedMinutes(currentValue);

  const incrementedValue = currentValue + delta;
  let result =
    incrementedValue < 0
      ? 60 - props.increment
      : incrementedValue > 59
      ? 0
      : incrementedValue;

  const isIncrementedInRange = allowedMinutes(result);

  if (isCurrentInRange && !isIncrementedInRange) {
    result = incrementMinutesValue(delta, result, true);
  } else if (!isCurrentInRange && !isIncrementedInRange) {
    // if value is between not allowed values
    // e.g after changing dayTime manually inputted value
    const activeValues = document.querySelectorAll(
      ".timepicker-time-tips-minutes:not(.disabled)"
    );

    const minV = props.min as string;
    const maxV = props.max as string;

    const min = props.min && getTimeUnits(minV);
    const max = props.max && getTimeUnits(maxV);

    if (!activeValues.length) {
      if (delta > 0) {
        result = min && min.minutes ? min.minutes : 0;
        hours.value = allowedHours(hours.value)
          ? hours.value
          : min
          ? min.hours
          : 1;
        changeDayTime((min && min.ampm) || (max && max.ampm) || dayTime.value);
      } else if (delta < 0) {
        result =
          max && max.minutes
            ? max.minutes
            : min && min.minutes
            ? min.minutes
            : 0;
        hours.value = allowedHours(hours.value)
          ? hours.value
          : max
          ? max.hours
          : props.hoursFormat;
        changeDayTime((max && max.ampm) || (min && min.ampm) || dayTime.value);
      }
    } else {
      result = incrementMinutesValue(delta, result, true);
    }
  }

  if (recursion) {
    return result;
  }

  minutes.value = result;
};

const handleOkClick = () => {
  if (!allowedHours(hours.value) || !allowedMinutes(minutes.value)) {
    return;
  }
  inputValue.value =
    props.hoursFormat === 12
      ? `${computedHours.value}:${
          computedMinutes.value
        } ${dayTime.value.toUpperCase()}`
      : `${computedHours.value}:${computedMinutes.value}`;

  close();
  unitsMode.value = "hours";
};

const handleClearClick = () => {
  inputValue.value = "";
  hours.value = 12;
  minutes.value = 0;
  unitsMode.value = "hours";
  dayTime.value = "am";
};

const handleCancelClick = () => {
  if (!inputValue.value) {
    handleClearClick();
  }
  close();
  formatToAmPm(inputValue.value);
};

const handleInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  const { value } = target;
  if (checkFormatValidity(value.trimEnd())) {
    formatToAmPm(value);
  }
};

watch(
  () => inputValue.value,
  (cur) => {
    const current = cur as string;
    checkFormatValidity(current.trimEnd(), true);
    emit("update:modelValue", cur);
  }
);

const formatToAmPm = (date: string | Date) => {
  if (date === "") return;
  let _hours;
  let _minutes;
  let _amOrPm;

  if (typeof date !== "string" && isValidDate(date)) {
    _hours = date.getHours();
    _minutes = date.getMinutes();
    _hours %= 12;
    _hours = _hours || 12;
    _amOrPm = _hours >= 12 ? "PM" : "AM";
  } else if (typeof date === "string" && !isValidDate(date)) {
    const {
      hours: inputHours,
      minutes: inputMinutes,
      ampm = null,
    } = getTimeUnits(date);

    if (ampm) {
      _amOrPm = ampm;
    }

    _hours = inputHours;
    _minutes = inputMinutes;
  }

  hours.value = parseInt(_hours) < 10 ? `0${_hours}` : _hours;
  minutes.value = parseInt(_minutes) < 10 ? `0${_minutes}` : _minutes;

  changeDayTime(_amOrPm);
  chageUnitsMode("hours");

  return `${hours.value}:${minutes.value} ${
    props.hoursFormat === 12 ? dayTime.value : ""
  }`;
};

const getTimeUnits = (date: string, computedDayTime = null) => {
  let [time, ampm] = date.split(" ");
  let [hours, minutes]: string[] | number[] = time.split(":");

  if (props.hoursFormat === 12 && !ampm && computedDayTime) {
    ampm = computedDayTime;
  }

  hours = parseInt(hours);
  minutes = parseInt(minutes);
  return { hours, minutes, ampm };
};

provide("hours", computedHours);
provide("minutes", computedMinutes);
provide("unitsMode", unitsMode);
provide("dayTime", dayTime);

provide("changeDayTime", changeDayTime);
provide("chageUnitsMode", chageUnitsMode);
provide("updateHoursValue", updateHoursValue);
provide("incrementHoursValue", incrementHoursValue);
provide("updateMinutesValue", updateMinutesValue);
provide("incrementMinutesValue", incrementMinutesValue);
provide("handleOkClick", handleOkClick);
provide("handleClearClick", handleClearClick);
provide("handleCancelClick", handleCancelClick);

// ------------- KEYBOARD NAVIGATION -------------
const unfocusedIncrement = (increment: number) => {
  const button = modalRef.value.$refs.modalRef.querySelector(
    ".timepicker-hour"
  ) as HTMLElement | null;
  if (button) {
    button.focus();
    unitsMode.value = "hours";
    handleChangeTime(increment);
  }
};

const handleArrowKeydown = (
  activeElement: HTMLElement | null,
  unitToIncrement: string | null,
  direction: number = 1
) => {
  activeElement === document.body && unfocusedIncrement(direction);
  unitToIncrement && handleChangeTime(direction);
};

const handleKeydown = (e: KeyboardEvent) => {
  if (!isActive.value) return;
  const { key } = e;

  const activeElement = document.activeElement as HTMLElement;
  const unitToIncrement = activeElement.classList.contains("timepicker-hour")
    ? "hours"
    : activeElement.classList.contains("timepicker-minute")
    ? "minutes"
    : null;

  if (unitToIncrement) {
    unitsMode.value = unitToIncrement;
  }

  if (key === "Escape") {
    close();
  }
  if (key !== "Tab") {
    e.preventDefault();
  } else if (key === "Tab" && e.target === document.body) {
    // will reinitial lost focus trap
    // arrow navigation causes focus trap to be lost and document.body to be focused
    modalRef.value.$refs.modalRef.focus();
  }

  switch (key) {
    case "ArrowUp":
      handleArrowKeydown(activeElement, unitToIncrement, 1);
      break;
    case "ArrowDown":
      handleArrowKeydown(activeElement, unitToIncrement, -1);
      break;
    case "ArrowLeft":
      handleChangeClockCircle("left");
      break;
    case "ArrowRight":
      handleChangeClockCircle("right");
      break;
    default:
      break;
  }
};

const handleChangeTime = (multiplier: number) => {
  if (unitsMode.value === "hours") {
    incrementHoursValue(1 * multiplier);
  } else {
    incrementMinutesValue(props.increment * multiplier);
  }
};
const handleChangeClockCircle = (side: string) => {
  if (props.hoursFormat === 12 || unitsMode.value === "minutes") {
    return;
  }

  if (side === "left" && hours.value < 12) {
    hours.value += 12;
  }

  if (side === "right" && hours.value > 12) {
    hours.value -= 12;
  }
};

const blurInput = () => {
  inputRef.value.$refs.inputRef.focus();
  inputRef.value.$refs.inputRef.blur();
};

const focusToggler = () => {
  if (btnRef.value) {
    btnRef.value.focus();
  } else {
    inputRef.value.$refs.inputRef.focus();
  }
};

// ------------- VALIDATION -------------
const valid = ref(props.isValid);
const validated = ref(props.isValidated);

watchEffect(() => {
  valid.value = props.isValid;
});
watchEffect(() => {
  validated.value = props.isValidated;
});

const validationTitle = computed(() => {
  return props.hoursFormat === 12
    ? "Date should match HH:MM 12-hour format, optional leading 0, mandatory meridiems (AM/PM)"
    : "Date should match HH:MM 24-hour format, optional leading 0";
});
const validationPattern = computed(() => {
  return props.hoursFormat === 12
    ? // eslint-disable-next-line no-useless-escape
      /\b((1[0-2]|0?[1-9]):([0-5][0-9])\s?([AaPp][Mm]))/
    : /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
});

const checkFormatValidity = (date: string, clear = undefined) => {
  if (!validationPattern.value.test(date)) {
    validated.value = true;
    valid.value = false;

    emit("update:isValid", false);
    emit("update:isValidated", true);

    return false;
  } else {
    // if clear - we do not want to show that is ok - just clear previous state
    validated.value = props.validLabel ? true : clear ? false : true;
    valid.value = true;

    emit("update:isValid", true);
    emit("update:isValidated", validated.value);

    return true;
  }
};

const isValidDate = (date) => {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
};

const getMinutesValue = ({
  hours,
  minutes,
  ampm,
}: {
  hours: number;
  minutes: number;
  ampm: string;
}) => {
  ampm = ampm ? ampm.toLowerCase() : ampm;

  const addition =
    props.hoursFormat === 12 && ampm === "pm" && hours !== 12 ? 12 * 60 : 0;

  minutes = minutes || 0;

  return hours * 60 + minutes + addition;
};

const propMinMaxIncludesDayTime = (
  propMin: undefined | number | string,
  propMax: undefined | number | string
) => {
  // if only min is passed
  if (propMin && typeof propMin === "string" && !propMax) {
    return propMin.split(" ")[1] === "AM" || propMin.split(" ")[1] === "PM";
  }
  // if only max is passed
  else if (propMax && typeof propMax === "string" && !propMin) {
    return propMax.split(" ")[1] === "AM" || propMax.split(" ")[1] === "PM";
  }
  // if both are passed
  else if (
    propMin &&
    propMax &&
    typeof propMin === "string" &&
    typeof propMax === "string"
  ) {
    return (
      (propMin.split(" ")[1] === "AM" || propMin.split(" ")[1] === "PM") &&
      (propMax.split(" ")[1] === "AM" || propMax.split(" ")[1] === "PM")
    );
  }
  // if there is no max or min prop
  return false;
};

const maxBeforeMin = (min: string | number, max: string | number) => {
  const minV = typeof min === "number" ? min : parseFloat(min.split(":")[0]);
  const maxV = typeof max === "number" ? max : parseFloat(max.split(":")[0]);

  return minV > maxV;
};

const allowedHours = (value: number) => {
  if (!props.max && !props.min && !props.disablePast && !props.disableFuture) {
    return value;
  }

  let _max = computedMaxValue.value;
  let _min = computedMinValue.value;
  let _disableProps = computedDisablePropsValue.value;

  if (_max && _min && _max < _min) {
    [_max, _min] = [_min, _max];
  }
  _min = _min - (_min % 60);
  _disableProps = _disableProps - (_disableProps % 60);

  const addition =
    props.hoursFormat === 12 && dayTime.value.toLowerCase() === "pm"
      ? 12 * 60
      : 0;
  let selectedHourValue = value * 60 + addition;

  if (
    (props.hoursFormat === 12 &&
      propMinMaxIncludesDayTime(props.min, props.max)) ||
    props.disableFuture ||
    props.disablePast
  ) {
    if (selectedHourValue === 12 * 60) {
      selectedHourValue = 0;
    }

    if (selectedHourValue === 24 * 60) {
      selectedHourValue = 12 * 60;
    }
  }

  let boundryCondition = true;

  if (props.min)
    boundryCondition = boundryCondition && selectedHourValue >= _min;
  if (props.max)
    boundryCondition = boundryCondition && selectedHourValue <= _max;
  if (props.disablePast)
    boundryCondition = boundryCondition && selectedHourValue >= _disableProps;
  if (props.disableFuture)
    boundryCondition = boundryCondition && selectedHourValue <= _disableProps;

  if (
    props.min &&
    props.max &&
    maxBeforeMin(props.min, props.max) &&
    _min !== selectedHourValue &&
    _max !== selectedHourValue
  )
    boundryCondition = !boundryCondition;

  return boundryCondition;
};

const allowedMinutes = (value: number) => {
  if (!props.max && !props.min && !props.disablePast && !props.disableFuture) {
    return value / props.increment === Math.floor(value / props.increment);
  }

  let _max = computedMaxValue.value;
  let _min = computedMinValue.value;

  if (_max && _min && _max < _min) {
    [_max, _min] = [_min, _max];
  }

  const addition =
    props.hoursFormat === 12 && dayTime.value.toLowerCase() === "pm"
      ? 12 * 60
      : 0;

  let hourValue = hours.value * 60 + addition;

  if (
    (props.hoursFormat === 12 &&
      propMinMaxIncludesDayTime(props.min, props.max)) ||
    props.disableFuture ||
    props.disablePast
  ) {
    if (hourValue === 12 * 60) {
      hourValue = 0;
    }

    if (hourValue === 24 * 60) {
      hourValue = 12 * 60;
    }
  }

  let boundryCondition = true;

  if (props.min)
    boundryCondition = boundryCondition && hourValue + value >= _min;
  if (props.max)
    boundryCondition = boundryCondition && hourValue + value <= _max;
  if (props.disablePast)
    boundryCondition =
      boundryCondition && hourValue + value >= computedDisablePropsValue.value;
  if (props.disableFuture)
    boundryCondition =
      boundryCondition && hourValue + value <= computedDisablePropsValue.value;

  if (
    props.min &&
    props.max &&
    maxBeforeMin(props.min, props.max) &&
    hourValue + value !== _min &&
    hourValue + value !== _max
  )
    boundryCondition = !boundryCondition;

  return (
    boundryCondition &&
    value / props.increment === Math.floor(value / props.increment)
  );
};

provide("allowedHours", allowedHours);
provide("allowedMinutes", allowedMinutes);

// ------------- OPENING/CLOSING METHODS -------------
const isActive = ref(false);
const shouldClockAnimateOnShow = ref(true);
const setClockAnimateOnShow = (value: boolean) => {
  shouldClockAnimateOnShow.value = value;
};

const debounce = ref(false);

const handleSelfOpen = () => !props.icon && props.selfOpen && togglePicker();

const togglePicker = () => {
  if (debounce.value) return;

  if (!isActive.value) {
    open();
  } else {
    close();
  }
};

const open = () => {
  if (debounce.value) return;
  debounce.value = true;

  isActive.value = true;
  emit("open");
  blurInput();
  setTimeout(() => (debounce.value = false), 250);
};

const close = () => {
  if (debounce.value) return;
  debounce.value = true;

  isActive.value = false;
  emit("close");
  focusToggler();
  setClockAnimateOnShow(true);
  setTimeout(() => (debounce.value = false), 250);
};

provide("isActive", isActive.value);
provide("close", close);
provide("shouldClockAnimateOnShow", shouldClockAnimateOnShow);
provide("setClockAnimateOnShow", setClockAnimateOnShow);

// ------------- LIFECYCLE METHODS -------------

onMounted(() => {
  if (props.modelValue) {
    inputValue.value = formatToAmPm(props.modelValue).toUpperCase();
  } else {
    if (props.max) {
      const maxV = props.max as string;
      const max = getTimeUnits(maxV);
      hours.value = max.hours < 12 ? max.hours : 12;
      minutes.value = max.hours < 12 ? max.minutes : 0;
    }
    if (props.min) {
      const minV = props.min as string;
      const min = getTimeUnits(minV);
      hours.value = min.hours > hours.value ? min.hours : hours.value;
      minutes.value = min.hours > hours.value ? min.minutes : 0;
    }
  }

  on(window, "keydown", handleKeydown);
});

onUnmounted(() => {
  off(window, "keydown", handleKeydown);
});

defineExpose({
  togglePicker,
  open,
  close,
});
</script>
