<template>
  <MDBInput
    :id="uid"
    ref="inputRef"
    v-model="inputValue"
    :label="label"
    :wrapperClass="inputWrapperClass"
    v-bind="$attrs"
    :invalidFeedback="invalidLabel"
    :validFeedback="validLabel"
    aria-haspopup="dialog"
    :isValid="valid"
    :isValidated="validated"
    :disabled="disabled"
    @click.stop="handleInputToggle"
    @keydown.enter="handleInputToggle"
  >
    <button
      v-if="icon && toggleButton"
      ref="btnRef"
      type="button"
      class="datetimepicker-toggle-button"
      aria-haspopup="dialog"
      :disabled="disabled"
      @click="toggleDatepicker"
    >
      <i :class="customIconClass" /></button
  ></MDBInput>
  <div v-show="false">
    <MDBDatepicker
      ref="datepickerRef"
      v-model="datepickerModel"
      v-model:isValidated="isDatepickerValidated"
      v-model:isValid="isDatepickerValid"
      label="Select a date"
      :format="dateFormat"
      v-bind="datepickerProps"
      :disablePast="disablePast"
      :disableFuture="disableFuture"
      :container="container"
      @cancel="handleCancel"
      @close="toggleTimepicker"
    />
    <MDBTimepicker
      ref="timepickerRef"
      v-model:isValidated="isTimepickerValidated"
      v-model:isValid="isTimepickerValid"
      v-model="timepickerModel"
      label="Select a time"
      v-bind="timepickerProps"
      :container="container"
      :disablePast="disableTimepickerPast"
      :disableFuture="disableTimepickerFuture"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDateTimepicker",
};
</script>

<script setup lang="ts">
import {
  ref,
  computed,
  watchEffect,
  watch,
  toRaw,
  provide,
  PropType,
} from "vue";
import { getUID } from "../../../../src/components/utils/getUID";
import MDBDatepicker from "../../../../src/components/pro/forms/MDBDatepicker/MDBDatepicker.vue";
import MDBInput from "../../../../src/components/free/forms/MDBInput.vue";
import MDBTimepicker from "./MDBTimepicker/MDBTimepicker.vue";
import isToday from "dayjs/plugin/isToday";
import dayjs from "dayjs";

dayjs.extend(isToday);

const props = defineProps({
  datepicker: Object as PropType<{
    [props: string]: string | Function | boolean | Date | string[] | number;
  }>,
  defaultTime: String,
  defaultDate: String,
  disabled: Boolean,
  modelValue: {
    type: [String, Date],
  },
  label: String,
  icon: {
    type: Boolean,
    default: true,
  },
  iconClass: {
    type: String,
    default: "far fa-calendar fa-sm",
  },
  id: String,
  inline: Boolean,
  inputToggle: Boolean,
  invalidLabel: {
    type: String,
    default: "Invalid date format",
  },
  isValid: Boolean,
  isValidated: Boolean,
  validLabel: {
    type: String,
  },
  timepicker: Object as PropType<{
    [props: string]: string | boolean | number;
  }>,
  toggleButton: {
    type: Boolean,
    default: true,
  },
  dateTimeSeparator: {
    type: String,
    default: ",",
  },
  dateFormat: {
    type: String,
    default: "DD/MM/YYYY",
  },
  disablePast: {
    type: Boolean,
    default: false,
  },
  disableFuture: {
    type: Boolean,
    default: false,
  },
  container: {
    type: String,
    default: "body",
  },
});

const emit = defineEmits([
  "update:isValid",
  "update:isValidated",
  "update:modelValue",
  "open",
  "close",
]);

// ------------- REFS -------------
const datepickerRef = ref<InstanceType<typeof MDBDatepicker> | null>(null);
const timepickerRef = ref<InstanceType<typeof MDBTimepicker> | null>(null);
const inputRef = ref(null);
const btnRef = ref(null);

const uid = props.id || getUID("MDBInput-");

provide("dateTimepicker", inputRef);

// ------------- STYLING -------------
const inputWrapperClass = computed(() => {
  return "datetimepicker";
});
const customIconClass = computed(() => {
  return ["timepicker-icon", props.iconClass];
});

// ------------- STATE MANAGEMENT -------------
const timeDateValue =
  typeof props.modelValue === "string" && props.modelValue !== ""
    ? props.modelValue.split(",")
    : props.modelValue;

const datepickerModel = ref(
  props.defaultDate || timeDateValue[0]?.trim() || ""
);
const timepickerModel = ref(
  props.defaultTime || timeDateValue[1]?.trim() || ""
);

const separator = ref(props.dateTimeSeparator);

const inputValue = computed({
  get() {
    if (props.dateFormat.includes(separator.value)) {
      separator.value = "@@";
    }
    if (datepickerModel.value && timepickerModel.value) {
      return `${datepickerModel.value}${separator.value} ${timepickerModel.value}`;
    }
    return "";
  },
  set(newDate) {
    const [date, time] = newDate.split(separator.value);
    datepickerModel.value = date;
    timepickerModel.value = time;
  },
});

watch(
  () => inputValue.value,
  (cur) => {
    emit("update:modelValue", cur);
  }
);

watch(
  () => props.modelValue,
  (curr, prev) => {
    if (curr === prev || typeof curr !== "string") {
      return;
    }

    const value = curr.split(separator.value);

    if (value[0] === "" || !value[1]) {
      datepickerModel.value = "";
      timepickerModel.value = "";
      return;
    }

    datepickerModel.value = value[0];
    timepickerModel.value = value[1].trim();
  }
);

const disableTimepickerPast = computed(() => {
  const isToday = dayjs(datepickerModel.value, props.dateFormat).isToday();
  return props.disablePast && isToday;
});

const disableTimepickerFuture = computed(() => {
  const isToday = dayjs(datepickerModel.value, props.dateFormat).isToday();
  return props.disableFuture && isToday;
});

// ------------- HANDLE PICKERS -------------
const shouldTimepickerOpen = ref(true);
const timepickerProps = computed(() => {
  return {
    ...toRaw(props.timepicker),
    inline: props.inline,
  };
});
const datepickerProps = computed(() => {
  return {
    ...toRaw(props.datepicker),
    inline: props.inline,
  };
});

const toggleDatepicker = () => {
  datepickerRef.value.open();
};

const toggleTimepicker = () => {
  if (!shouldTimepickerOpen.value) return;
  timepickerRef.value.open();
};

const handleCancel = () => {
  shouldTimepickerOpen.value = false;
  setTimeout(() => (shouldTimepickerOpen.value = true), 500);
};

const handleInputToggle = () => {
  if (props.inputToggle) {
    toggleDatepicker();
  }
};

const changePicker = (picker: string) => {
  if (picker === "timepicker") {
    datepickerRef.value.close();
  } else if (picker === "datepicker") {
    timepickerRef.value.close();
    setTimeout(() => toggleDatepicker(), 300);
  }
};

const open = () => {
  datepickerRef.value.open();
  emit("open");
};

const close = () => {
  datepickerRef.value.close();
  timepickerRef.value.close();
  emit("close");
};

provide("changePicker", changePicker);

// ------------- VALIDATION -------------
const isTimepickerValidated = ref(false);
const isTimepickerValid = ref(true);

const isDatepickerValidated = ref(false);
const isDatepickerValid = ref(true);

const valid = ref(props.isValid);
const validated = ref(props.isValidated);

watchEffect(() => {
  valid.value = props.isValid;
});
watchEffect(() => {
  validated.value = props.isValidated;
});

watch(
  () => [isTimepickerValid.value, isDatepickerValid.value],
  (cur) => {
    if (cur.some((value) => typeof value === "boolean" && value === false)) {
      emit("update:isValid", false);
      setInputsInvalid();
    }

    if (cur.every((value) => !!value)) {
      emit("update:isValid", true);
    }
  },
  { immediate: true }
);

watch(
  () => [isTimepickerValidated.value, isDatepickerValidated.value],
  (cur) => {
    if (cur.some((value) => value)) emit("update:isValidated", true);

    if (cur.every((value) => typeof value === "boolean" && value === false)) {
      emit("update:isValidated", false);
    }
  },
  { immediate: true }
);

const setInputsInvalid = () => {
  if (!validated.value) return;
  datepickerModel.value = isDatepickerValid.value ? datepickerModel.value : "";
  timepickerModel.value = isTimepickerValid.value ? timepickerModel.value : "";
};

defineExpose({
  open,
  close,
});
</script>
