<template>
  <router-link
    v-if="to"
    :id="uid"
    ref="sidenavLink"
    v-mdb-ripple="rippleConfig"
    :class="className"
    :exact="exact"
    :to="to"
    :target="tab"
    active-class="active"
    exact-active-class="exact-active"
    tabindex="0"
    v-bind="$attrs"
    @keydown="handleKeyDown"
    @click="toggleActive"
  >
    <slot />
    <MDBIcon
      v-if="icon"
      :icon="icon"
      :iconStyle="iconStyle"
      :size="iconSize"
      :flag="iconFlag"
      :class="iconClasses"
      :style="iconTransform"
    />
  </router-link>
  <component
    :is="tag"
    v-else
    :id="uid"
    ref="sidenavLink"
    v-mdb-ripple="rippleConfig"
    :href="href"
    :class="className"
    :target="tab"
    tabindex="0"
    v-bind="$attrs"
    @keydown="handleKeyDown"
    @click="toggleActive"
  >
    <slot />
    <MDBIcon
      v-if="icon"
      :icon="icon"
      :iconStyle="iconStyle"
      :size="iconSize"
      :flag="iconFlag"
      :class="iconClasses"
      :style="iconTransform"
    />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBSideNavLink",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, inject, onMounted, ref, watch, PropType } from "vue";
import type { Ref } from "vue";
import MDBIcon from "../../free/content-styles/MDBIcon.vue";
import vMdbRipple from "../../../../src/directives/free/mdbRipple";
import { getUID } from "../../../../src/components/utils/getUID";

const props = defineProps({
  tag: {
    type: String,
    default: "a",
  },
  id: String,
  href: String,
  to: [String, Object] as PropType<string | { [props: string]: string }>,
  exact: {
    type: Boolean,
    default: false,
  },
  newTab: {
    type: Boolean,
    default: false,
  },
  classes: String,
  icon: String,
  iconStyle: String,
  iconSize: String,
  iconFlag: String,
  iconClass: String,
  modelValue: Boolean,
  ripple: {
    type: [Object, Boolean] as PropType<
      { [props: string]: string | number | boolean } | boolean
    >,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const uid = ref(props.id || getUID("MDBSideNavLink-"));
const sidenavLink = ref<HTMLAnchorElement | string>("sidenavLink");
const hasCollapsibleContent = inject<Ref<boolean> | false>(
  "hasCollapsibleContent",
  false
);
const activeNode = inject<Ref<string> | false>("activeNode", false);
const setActiveNode = inject<
  false | ((id: string, node: HTMLAnchorElement) => void)
>("setActiveNode", false);
const activeClass = computed(
  () => activeNode && activeNode.value === uid.value
);

const className = computed(() => {
  return ["sidenav-link", activeClass.value ? "active" : null, props.classes];
});

const isOpened = ref(props.modelValue);
const rotateIcon = ref(props.modelValue);

watch(
  () => props.modelValue,
  (cur) => {
    isOpened.value = cur;
    rotateIcon.value = cur;
  }
);

let isToggling = false;
const toggleActive = () => {
  if (isToggling) {
    return;
  }

  isToggling = true;

  if (hasCollapsibleContent && hasCollapsibleContent.value) {
    emit("update:modelValue", !props.modelValue);
  } else {
    isOpened.value = true;
  }
  setActiveNode &&
    setActiveNode(uid.value, sidenavLink.value as HTMLAnchorElement);

  setTimeout(() => {
    isToggling = false;
  }, 300);
};

const tab = computed(() => {
  if (props.newTab) {
    return "_blank";
  }

  return false;
});

const iconClasses = computed(() => {
  return ["rotate-icon", props.iconClass];
});

const iconTransform = computed(() => {
  return { transform: `rotate(${isOpened.value ? 180 : 0}deg)` };
});

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === "Enter") {
    if (hasCollapsibleContent && hasCollapsibleContent.value) {
      emit("update:modelValue", !props.modelValue);
    } else {
      toggleActive();
    }
  }
};

const openCollapse = inject<(() => void) | false>("openCollapse", false);

const openParentCollapse = () => {
  if (openCollapse) {
    openCollapse();
    emit("update:modelValue", true);
  }
};

const rippleConfig = ref(
  typeof props.ripple === "boolean"
    ? { color: inject<string>("sidenavColor", "primary") }
    : {
        color: inject<string>("sidenavColor", "primary"),
        ...props.ripple,
      }
);

onMounted(() => {
  if (
    window.location.href === props.href ||
    (window.location.hash && window.location.hash.substring(1) === props.to) ||
    (window.location.pathname !== "/" && window.location.pathname === props.to)
  ) {
    toggleActive();
    openParentCollapse();
  }
});
</script>
