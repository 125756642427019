<template>
  <component
    :is="tag"
    ref="wrapper"
    v-mdb-on-scroll="
      trigger === AnimationTrigger.onScroll ? activeScrollEvents : false
    "
    :class="className"
    :style="animationWrapperStyle"
    @mouseenter="() => isOnHover && startAnimation()"
    @click="() => isOnClick && startAnimation()"
  >
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBAnimation",
};
</script>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, watch, watchEffect, ref } from "vue";
import { on, off } from "../../utils/MDBEventHandlers";
import vMdbOnScroll from "../../../directives/free/mdbOnScroll";

enum AnimationTrigger {
  onHover = "onHover",
  onClick = "onClick",
  onLoad = "onLoad",
  onScroll = "onScroll",
  manually = "manually",
}

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  trigger: {
    type: String,
    default: "onClick",
    validator: (type: string) =>
      ["onHover", "onClick", "manually", "onLoad", "onScroll"].indexOf(type) >
      -1,
  },
  modelValue: Boolean,
  animation: {
    type: [String, Boolean],
    default: "fade-in",
  },
  duration: {
    type: Number,
    default: 500,
  },
  reset: {
    type: Boolean,
    default: false,
  },
  delay: {
    type: Number,
    default: 0,
  },
  interval: {
    type: Number,
    default: 0,
  },
  repeat: {
    type: [Boolean, Number],
    default: false,
  },
  direction: {
    type: String,
    default: "normal",
  },
  repeatOnScroll: {
    type: Boolean,
    default: false,
  },
  scrollOffset: {
    type: Number,
    default: 0,
  },
  showOnLoad: {
    type: Boolean,
    default: true,
  },
  beginHidden: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["hide", "show", "start", "end", "update:modelValue"]);

const animationClasses = ref("");
const beginHidden = ref(props.beginHidden);

const className = computed(() => [
  animationClasses.value,
  beginHidden.value ? "invisible" : "",
]);
const setAnimationClasses = () => {
  animationClasses.value = `animation ${props.animation}`;
  emit("start", wrapper.value);
};

const clearAnimationClasses = () => {
  animationClasses.value = "";

  off(wrapper.value, "animationend", clearAnimationClasses);

  if (
    props.trigger === AnimationTrigger.manually &&
    props.modelValue &&
    !props.repeat
  ) {
    emit("update:modelValue", false);
  }
};

const emitEnd = () => {
  emit("end", wrapper.value);

  off(wrapper.value, "animationend", emitEnd);
};

const animationWrapperStyle = computed(() => {
  return {
    animationIterationCount:
      !props.interval && props.repeat === true ? "infinite" : props.repeat,
    animationDelay: `${props.delay}ms`,
    animationDuration: `${props.duration}ms`,
    animationDirection: props.direction,
  };
});

const startAnimation = () => {
  if (props.beginHidden) {
    beginHidden.value = false;
  }

  setAnimationClasses();

  if (isOnHover.value) {
    resetHoverEvents();
  }

  if (props.interval) {
    on(wrapper.value, "animationend", startInterval);
  }

  if (props.reset) {
    bindAnimationReset();
  }

  on(wrapper.value, "animationend", emitEnd);
};

const stopAnimation = () => {
  clearAnimationClasses();
};

watch(
  () => props.animation,
  () => {
    startAnimation();
  }
);

const isOnHover = ref(props.trigger === AnimationTrigger.onHover);
const activateHoverEvents = () => {
  isOnHover.value = true;
};

const isOnClick = ref(props.trigger === AnimationTrigger.onClick);
const activateClickEvents = () => {
  isOnClick.value = true;
};

const isOnManual = ref(props.trigger === AnimationTrigger.manually);
const activateManualEvents = () => {
  isOnManual.value = true;
};

const activeScrollEvents = {
  callback: (value: string) => handleScroll(value),
  offset: props.scrollOffset,
  repeatOnShow: props.repeatOnScroll,
};
const handleScroll = (scrollStatus: string) => {
  switch (scrollStatus) {
    case "hasShown":
      startAnimation();
      showAnimateElement();
      break;
    case "hasHidden":
      if (props.repeatOnScroll) {
        clearAnimationClasses();
        if (!props.showOnLoad || props.scrollOffset > 0) {
          hideAnimateElement();
        }
      }
      break;
    default:
      break;
  }
};

const hideScrollElementOnShow = () => {
  if (!props.showOnLoad || props.scrollOffset > 0) {
    hideAnimateElement();
  }
};

const hideAnimateElement = () => {
  wrapper.value.style.visibility = "hidden";
  emit("hide", wrapper.value);
};

const showAnimateElement = () => {
  wrapper.value.style.visibility = "visible";
  emit("show", wrapper.value);
};

const triggerManual = ref(props.modelValue);
watchEffect(() => {
  triggerManual.value = props.modelValue;
});

watch(
  () => triggerManual.value,
  (cur, prev) => {
    if (cur && isOnManual.value) {
      startAnimation();
    } else if (prev === true) {
      stopAnimation();
    }
  }
);

const startInterval = () => {
  clearAnimationClasses();
  setTimeout(() => {
    setAnimationClasses();
  }, props.interval);
};

const resetHoverEvents = () => {
  isOnHover.value = false;

  setTimeout(() => {
    isOnHover.value = true;
  }, props.duration + 100);
};

const bindAnimationReset = () => {
  on(wrapper.value, "animationend", clearAnimationClasses);
};

const wrapper = ref<HTMLDivElement | HTMLElement | null>(null);

onMounted(() => {
  if (!props.animation) {
    return;
  }

  switch (props.trigger) {
    case AnimationTrigger.onHover:
      activateHoverEvents();
      break;
    case AnimationTrigger.onClick:
      activateClickEvents();
      break;
    case AnimationTrigger.onLoad:
      startAnimation();
      break;
    case AnimationTrigger.manually:
      activateManualEvents();
      break;
    case AnimationTrigger.onScroll:
      hideScrollElementOnShow();
      break;
    default:
      break;
  }
});

onUnmounted(() => {
  off(wrapper.value, "animationend", clearAnimationClasses);
  off(wrapper.value, "animationend", startInterval);
  off(wrapper.value, "animationend", emitEnd);
});

defineExpose({
  startAnimation,
  stopAnimation,
});
</script>
