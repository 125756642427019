<template>
  <transition
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @after-leave="afterLeave"
  >
    <component
      :is="tag"
      v-if="isActive"
      ref="root"
      :class="[wrapperClass, nonInvasive && 'non-invasive']"
      :style="[
        backdropStyle,
        backdropOverflowStyle,
        backdropPointerEventsStyle,
      ]"
      :aria-hidden="!isActive"
      :aria-modal="isActive ? true : null"
      :aria-labelledby="labelledby"
      role="dialog"
      @mousedown.self="clickFromBackdrop = true"
      @click.self="
        () => {
          if (nonInvasive || !clickFromBackdrop) {
            return;
          }
          if (staticBackdrop) {
            animateStaticBackdrop();
          } else {
            closeModal();
            clickFromBackdrop = false;
          }
        }
      "
      @show="emitEvent($event, 'show')"
      @shown="emitEvent($event, 'shown')"
      @hide="emitEvent($event, 'hide')"
      @hidden="emitEvent($event, 'hidden')"
    >
      <div ref="dialog" :class="[dialogClass, dialogClassPro]" role="document">
        <div class="modal-content" :style="computedContentStyle">
          <slot />
        </div>
      </div>
    </component>
  </transition>
</template>

<script lang="ts">
import MDBModal from "../../../../src/components/free/components/MDBModal.vue";

export default {
  name: "MDBModal",
  extends: MDBModal,
};
</script>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";
import useMDBModal from "../../../composables/free/useMDBModal";
import { off } from "../../utils/MDBEventHandlers";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  modelValue: Boolean,
  size: {
    type: String,
    validator: (value: string) =>
      ["sm", "lg", "xl"].indexOf(value.toLowerCase()) > -1,
  },
  removeBackdrop: {
    type: Boolean,
    default: false,
  },
  staticBackdrop: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  bgSrc: {
    type: String,
    default: "",
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: 400,
  },
  labelledby: String,
  fullscreen: {
    type: [Boolean, String],
    default: false,
  },
  animation: {
    type: Boolean,
    default: true,
  },
  dialogClasses: {
    type: String,
  },
  keyboard: {
    type: Boolean,
    default: true,
  },
  focus: {
    type: Boolean,
    default: true,
  },
  side: {
    type: Boolean,
    default: false,
  },
  frame: {
    type: [Boolean, String],
    default: false,
  },
  nonInvasive: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: "top",
  },
  position: String,
  keepOverflow: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "show",
  "shown",
  "hide",
  "hidden",
  "update:modelValue",
]);

const {
  dialogClass,
  wrapperClass,
  backdropOverflowStyle,
  computedContentStyle,
  root,
  dialog,
  isActive,
  closeModal,
  animateStaticBackdrop,
  afterEnter,
  afterLeave,
  scrollbarWidth,
  shouldOverflow,
  setScrollbar,
  thisElement,
  handleEscKeyUp,
  focusTrap,
  dialogTransform,
  clickFromBackdrop,
  isOnlyNonInvasiveModal,
  onlyNonInvasiveModal,
} = useMDBModal(props, emit);

const dialogClassPro = computed(() => {
  const classes = [];

  props.side && classes.push("modal-side");
  props.frame && classes.push("modal-frame");
  props.position && classes.push(`modal-${props.position}`);
  props.nonInvasive && classes.push("modal-non-invasive-show");

  return classes;
});

const value = ref(props.modelValue);
watchEffect(() => (value.value = props.modelValue));

watch(
  () => value.value,
  (cur) => {
    emit("update:modelValue", cur);
  }
);

// dialog classes - transform

const dialogTransformPro = computed(() => {
  if (props.direction === "right") {
    return "translate(25%,0)";
  } else if (props.direction === "bottom") {
    return "translate(0,25%)";
  } else if (props.direction === "left") {
    return "translate(-25%,0)";
  }
  return null;
});

const emitEvent = (
  e: Event,
  eventName: "show" | "shown" | "hide" | "hidden" | "update:modelValue"
) => {
  emit(eventName, e);
};

// non invasive modal
const backdropStyle = computed(() => {
  return props.removeBackdrop || props.nonInvasive
    ? false
    : { "background-color": `rgba(0,0,0, 0.5)` };
});

const backdropPointerEventsStyle = computed(() => {
  return props.nonInvasive ? { "pointer-events": "none" } : false;
});

const enter = (el: HTMLElement) => {
  shouldOverflow.value =
    dialogTransformPro.value === "translate(0,25%)" ? false : true;

  dialogTransform.value = dialogTransformPro.value || "translate(0, -25%)";

  (el.childNodes[0] as HTMLElement).style.transform = dialogTransform.value;
  el.style.opacity = "0";
  el.style.display = "block";

  setScrollbar();

  isOnlyNonInvasiveModal();
  let paddingValue = scrollbarWidth.value;
  document.body.style.overflowY = "hidden";

  if (props.nonInvasive) {
    paddingValue = 0;
    document.body.style.overflowY = "auto";
  }

  if (onlyNonInvasiveModal.value) {
    document.body.style.paddingRight = `${paddingValue}px`;
    el.style.paddingRight = `${scrollbarWidth.value}px`;
    document.body.classList.add("modal-open");
  }

  emit("show", root.value);
};

const beforeLeave = (el: HTMLElement) => {
  (el.childNodes[0] as HTMLElement).style.transform = dialogTransform.value;
  el.style.opacity = "0";

  if (onlyNonInvasiveModal.value) {
    setTimeout(() => {
      el.style.paddingRight = null;

      if (!props.nonInvasive) {
        document.body.style.paddingRight = null;
        document.body.style.overflowY = "auto";
      }

      document.body.classList.remove("modal-open");
    }, 200);
  }

  emit("hide", thisElement.value);

  if (props.keyboard) {
    off(window, "keyup", handleEscKeyUp);
  }
  if (props.focus && focusTrap.value) {
    focusTrap.value.removeFocusTrap();
  }
};
</script>
