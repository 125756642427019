<template>
  <component
    :is="tag"
    :class="tagClassName"
    @click="handleClick"
    @keydown.delete="handleDeletePress"
  >
    <div class="form-outline chips-input-wrapper" :class="notchClassName">
      <div
        v-for="(chip, index) of chipsList"
        :ref="setChipRef"
        :key="chip.id"
        v-mdb-ripple
        :contenteditable="chip.editable"
        :class="chipClassName(index)"
        @dblclick="handleEditable(chip.id, index)"
        @blur="disableEditable(chip.id, index)"
        @keydown.enter="disableEditable(chip.id, index)"
      >
        {{ chip.value }}
        <i
          v-show="chip.closeBtn"
          class="fas fa-times close"
          @click="handleCloseClick(chip)"
        />
      </div>
      <input
        :id="uid"
        ref="input"
        v-model="inputValue"
        class="form-control chips-input"
        :class="inputClassName"
        type="text"
        :placeholder="calculatePlaceholder"
        @blur="onBlur"
        @keyup.enter="handleAddingChips"
        @keydown.left="handleLeftPress"
        @keydown.right="handleRightPress"
        @keydown.down="handleDownPress"
        @keydown.up="handleUpPress"
      />
      <label
        v-if="props.label"
        ref="labelRef"
        :class="labelClassName"
        :for="uid"
      >
        {{ props.label }}
      </label>

      <div class="form-notch">
        <div
          class="form-notch-leading"
          :style="{ width: `${notchLeadingWidth}px` }"
        ></div>
        <div
          class="form-notch-middle"
          :style="{ width: `${notchMiddleWidth}px` }"
        ></div>
        <div class="form-notch-trailing"></div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBChipsInput",
};
</script>

<script setup lang="ts">
import vMdbRipple from "../../../../../src/directives/free/mdbRipple";
import { getUID } from "../../../utils/getUID";
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  reactive,
  PropType,
} from "vue";

interface ChipsListItem {
  value: string;
  id: string;
  editable: boolean | null;
  closeBtn: boolean;
  isActive: boolean | null;
}

const props = defineProps({
  id: String,
  tag: {
    type: String,
    default: "div",
  },
  chips: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  label: String,
  labelClasses: String,
  placeholder: String,
  secondaryPlaceholder: String,
  chipSize: String,
  value: Array as PropType<string[]>,
  editable: Boolean,
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const emit = defineEmits([
  "delete-chip",
  "deleted-chip",
  "add-chip",
  "added-chip",
  "arrow-down",
  "arrow-up",
  "arrow-left",
  "arrow-right",
  "update:modelValue",
]);

const chipsList = ref<ChipsListItem[]>([]);
const inputValue = ref("");
const input = ref<HTMLInputElement>(null);
const chipRef = ref([]);
const labelRef = ref(null);
const isReadyToDelete = ref(true);
const notchLeadingWidth = ref(9);
const notchMiddleWidth = ref(0);
const uid = props.id || getUID("MDBInput-");
const state = reactive({
  count: 0,
  activeStep: undefined,
  prevStep: undefined,
});

function addChip() {
  if (
    inputValue.value === "" ||
    chipsList.value.find((chip) => chip.value === inputValue.value)
  ) {
    inputValue.value = "";
    return;
  }

  const newChip = {
    value: inputValue.value,
    id: getUID("chip-"),
    editable: null,
    closeBtn: true,
    isActive: null,
  };
  chipsList.value.push(newChip);
  inputValue.value = "";

  emit("add-chip", chipsValueList.value);
  emit("added-chip", newChip);
}

// focus behavior
function handleClick(e: Event) {
  const target = e.target as HTMLElement;
  if (target.classList.contains("chip") || target.classList.contains("close")) {
    return;
  }
  input.value.focus();
}

function onBlur() {
  chipsList.value.forEach((chip) => (chip.isActive = null));
  handleAddingChips();
}

// handle adding new Chips:
function handleAddingChips() {
  if (/^ *$/.test(inputValue.value)) {
    inputValue.value = "";
    return;
  }
  addChip();
}

// handle removal - by pressing "Backspace" or "Delete":
function handleDeletePress() {
  if (!inputValue.value && isReadyToDelete.value) {
    const activeChipIndex = chipsList.value.findIndex(
      (chip) => chip.isActive === true
    );
    let deletedChip;

    if (activeChipIndex > -1) {
      deletedChip = chipsList.value.splice(activeChipIndex, 1);
      if (state.count) {
        if (state.prevStep > state.activeStep) {
          state.count = state.prevStep - 1;
        } else {
          state.count = state.activeStep - 1;
        }
      }

      if (chipsList.value[state.count]) {
        chipsList.value[state.count].isActive = true;
      }
    } else {
      deletedChip = chipsList.value.pop();

      if (state.count >= chipsList.value.length - 1) {
        state.count = chipsList.value.length - 1;
      }
    }

    emit("delete-chip", chipsValueList.value);
    emit("deleted-chip", deletedChip);
  }
}

// ... and by pressing the "close" button:
function handleCloseClick(chipName: ChipsListItem) {
  const array = chipsList.value;
  const index = array.indexOf(chipName);
  const deletedChip = array.splice(index, 1);

  if (state.count >= chipsList.value.length - 1) {
    state.count = chipsList.value.length - 1;
  }

  emit("delete-chip", chipsValueList.value);
  emit("deleted-chip", deletedChip);
}

function handleRightPress() {
  handleRightMove();
  emit("arrow-right");
}

function handleUpPress() {
  handleRightMove();
  emit("arrow-up");
}

function handleLeftPress() {
  handleLeftMove();
  emit("arrow-left");
}

function handleDownPress() {
  handleLeftMove();
  emit("arrow-down");
}

function handleRightMove() {
  if (state.activeStep === undefined) {
    chipsList.value[state.count].isActive = true;
    state.activeStep = state.count;
  } else {
    state.count++;
    chipsList.value[state.count - 1].isActive = null;
    if (state.count >= chipsList.value.length) {
      state.count = 0;
    }

    state.prevStep = state.activeStep;
    state.activeStep = state.count;

    chipsList.value[state.count].isActive = true;
  }
}

function handleLeftMove() {
  if (state.activeStep === undefined) {
    state.count = chipsList.value.length - 1;
    chipsList.value[state.count].isActive = true;
    state.activeStep = state.count;
  } else {
    state.count--;
    chipsList.value[state.count + 1].isActive = null;
    if (state.count <= -1) {
      state.count = chipsList.value.length - 1;
    }

    state.prevStep = state.activeStep;
    state.activeStep = state.count;

    chipsList.value[state.count].isActive = true;
  }
}

function handleEditable(id: string, index: number) {
  if (props.editable) {
    const filteredChip = chipsList.value.find((chip) => chip.id === id);
    if (filteredChip) {
      isReadyToDelete.value = false;
      filteredChip.editable = true;
      filteredChip.closeBtn = false;
    }

    if (chipRef.value[index]) {
      setTimeout(() => {
        chipRef.value[index].focus();
      }, 0);
    }
  }
}

function disableEditable(id: string, index: number) {
  if (props.editable) {
    isReadyToDelete.value = true;

    const filteredChip = chipsList.value.find((chip) => chip.id === id);
    if (filteredChip) {
      filteredChip.editable = false;
      filteredChip.closeBtn = true;
    }

    if (chipRef.value[index]) {
      filteredChip.value = chipRef.value[index].textContent.trim();
      if (filteredChip.value === "") {
        chipsList.value.splice(index, 1);
      }
    }
  }
}

function calcNotch() {
  if (labelRef.value) {
    notchMiddleWidth.value = labelRef.value.clientWidth * 0.8 + 8;
  }
}

function addInitialValues() {
  chipsList.value = [];
  if (props.modelValue.length > 0) {
    (props.modelValue as string[]).forEach((chip) => {
      chipsList.value.push({
        value: chip,
        id: getUID("chip-"),
        editable: null,
        closeBtn: true,
        isActive: null,
      });
    });
  }
}

const setChipRef = (el: HTMLDivElement) => {
  if (el) {
    chipRef.value.push(el);
  }
};

onBeforeUpdate(() => (chipRef.value = []));

onMounted(() => addInitialValues());

onUpdated(() => calcNotch());

const isNotEmpty = computed(() => chipsList.value.length > 0);

const chipsValueList = computed(() =>
  chipsList.value.map((chip) => chip.value)
);

watch(
  () => chipsValueList.value,
  (curr, old) => {
    // if the modelValue is the same, do nothing (prevent infinite loop)
    if ([...curr].toString() === [...old].toString()) {
      return;
    }
    emit("update:modelValue", chipsValueList.value);
  }
);

watch(
  () => props.modelValue,
  (curr, old) => {
    // if the modelValue is the same, do nothing (prevent infinite loop)
    if ([...curr].toString() === [...old].toString()) {
      return;
    }
    addInitialValues();
  }
);

const tagClassName = computed(() => ["chips", "chips-placeholder"]);

const chipClassName = (index: number) => {
  const isActive = chipsList.value.map((chip) => chip.isActive);

  return [
    "chip",
    props.chipSize && "chip-" + props.chipSize,
    isActive[index] && "active",
  ];
};

const notchClassName = computed(() => [
  isNotEmpty.value && "chips-padding chips-transition",
]);

const inputClassName = computed(() => [isNotEmpty.value && "active"]);

const labelClassName = computed(() => ["form-label", props.labelClasses]);

const calculatePlaceholder = computed(() => {
  const calculatedPlaceholder =
    !props.secondaryPlaceholder && props.placeholder
      ? props.placeholder
      : props.secondaryPlaceholder;
  const calculatedSecondaryPlaceholder =
    !props.placeholder && props.secondaryPlaceholder
      ? props.secondaryPlaceholder
      : props.placeholder;
  if (chipsList.value.length > 0) {
    return calculatedSecondaryPlaceholder;
  }
  return calculatedPlaceholder;
});
</script>

<style></style>
