import type { DirectiveBinding } from "vue";

interface ScrollLockElement extends HTMLElement {
  blockScroll: () => void;
  prevOverflowY: string;
  prevOverflowX: string;
  enableScroll: () => void;
}

export default {
  mounted(el: ScrollLockElement, binding: DirectiveBinding) {
    let { x: blockX, y: blockY } = binding.modifiers;
    const blocked = binding.value === undefined ? true : binding.value;

    if (blockX === undefined && blockY === undefined) {
      blockX = true;
      blockY = true;
    }

    el.blockScroll = () => {
      const body = document.querySelector("body");
      if (blockY && body) {
        el.prevOverflowY = body.style.overflowY;
        body.style.overflowY = "hidden";
      }

      if (blockX && body) {
        el.prevOverflowX = body.style.overflowX;
        body.style.overflowX = "hidden";
      }
    };

    el.enableScroll = () => {
      const body = document.querySelector("body");

      if (blockY && body) body.style.overflowY = el.prevOverflowY;
      if (blockX && body) body.style.overflowX = el.prevOverflowX;
    };

    if (blocked) el.blockScroll();
  },
  updated(el: ScrollLockElement, binding: DirectiveBinding) {
    if (binding.value === undefined) return;

    if (binding.value) el.blockScroll();
    else el.enableScroll();
  },
  unmounted(el: ScrollLockElement) {
    el.enableScroll();
  },
};
