<template>
  <component :is="tag" v-if="active" :class="className">
    <img v-if="src" :src="src" :alt="alt" />
    <slot />
    <i v-show="close" class="fas fa-times close" @click="handleCloseClick" />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBChip",
};
</script>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  src: String,
  alt: String,
  size: String,
  outline: String,
  close: Boolean,
  handleClose: Function,
});
const emit = defineEmits(["close-chip"]);

const active = ref(true);

function handleCloseClick() {
  if (props.handleClose) {
    props.handleClose();
  }
  emit("close-chip");
  active.value = false;
}

const className = computed(() => [
  "chip",
  props.size && `chip-${props.size}`,
  props.outline && `chip-outline btn-outline-${props.outline}`,
]);
</script>
