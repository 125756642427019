<template>
  <div ref="datepickerDateControlsRef" class="datepicker-date-controls">
    <button
      class="datepicker-view-change-button"
      :aria-label="switchToMultiYearViewLabel"
      @click.stop="changeView('year')"
    >
      {{ activeMonth }} {{ activeYear }}
    </button>
    <div class="datepicker-arrow-controls">
      <button
        class="datepicker-previous-button"
        :aria-label="prevMonthLabel"
        @click="goToPrevMonth"
      ></button>
      <button
        class="datepicker-next-button"
        :aria-label="nextMonthLabel"
        @click="goToNextMonth"
      ></button>
    </div>
  </div>

  <div
    ref="datepickerViewRef"
    class="datepicker-view"
    tabindex="0"
    @keydown.up.prevent="handleDayUp"
    @keydown.down.prevent="handleDayDown"
    @keydown.left.prevent="handleDayLeft"
    @keydown.right.prevent="handleDayRight"
    @keydown.enter.prevent="handleEnter"
    @keydown="handleKeydown"
    @keydown.tab="handleTab"
    @focus="isDatepickerViewFocused = true"
    @blur="isDatepickerViewFocused = false"
  >
    <table class="datepicker-table">
      <thead>
        <tr>
          <th
            v-for="(weekday, key) in weekdaysNarrow"
            :key="key"
            class="datepicker-day-heading"
            scope="col"
            :aria-label="weekdaysFull[key]"
          >
            {{ weekday }}
          </th>
        </tr>
      </thead>
      <tbody class="datepicker-table-body">
        <tr v-for="(week, key) in days" :key="key">
          <td
            v-for="(day, keyInWeek) in week"
            :key="keyInWeek"
            class="datepicker-cell datepicker-small-cell datepicker-day-cell"
            :class="[
              day.current && 'current',
              day.focused && isDatepickerViewFocused && 'focused',
              day.selected && 'selected',
              !day.selectable && 'disabled',
            ]"
            :aria-label="day.ariaLabel"
            :aria-selected="day.selected"
            @click="handleSelectDay(day)"
          >
            <div
              v-if="!day.disabled"
              class="datepicker-cell-content datepicker-small-cell-content"
            >
              {{ day.label }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerDayView",
};
</script>

<script setup lang="ts">
import dayjs from "dayjs";
import { inject, computed, ref, onMounted } from "vue";
import type { Ref } from "vue";

interface Day {
  current?: boolean;
  dayJS?: dayjs.Dayjs;
  disabled?: boolean;
  focused?: boolean;
  label?: number;
  selectable?: boolean;
  selected?: boolean;
  ariaLabel?: string;
}

const props = defineProps({
  inline: Boolean,
});

const activeDate = inject<Ref<dayjs.Dayjs>>("activeDate");
const selectedDate = inject<Ref<dayjs.Dayjs>>("selectedDate");
const focusedDate = inject<Ref<dayjs.Dayjs>>("focusedDate");
const monthsFull = inject<string[]>("monthsFull");
const weekdaysNarrow = inject<string[]>("weekdaysNarrow");
const weekdaysFull = inject<string[]>("weekdaysFull");
const prevMonthLabel = inject<string>("prevMonthLabel");
const nextMonthLabel = inject<string>("nextMonthLabel");
const startDay = inject<number>("startDay");
const switchToMultiYearViewLabel = inject<string>("switchToMultiYearViewLabel");

const activeMonth = computed(() => monthsFull[activeDate.value.month()]);
const activeYear = computed(() => activeDate.value.year());

const selectDay = inject<(day: Day) => void>("selectDay");
const goToPrevMonth = inject<() => void>("goToPrevMonth");
const goToNextMonth = inject<() => void>("goToNextMonth");
const changeView = inject<(newView: string) => void>("changeView");

const minDate = inject<Ref<dayjs.Dayjs>>("minDate");
const maxDate = inject<Ref<dayjs.Dayjs>>("maxDate");
const startDate = inject<Ref<dayjs.Dayjs>>("startDate");
const filter = inject<(item?: dayjs.Dayjs) => boolean>("filter");
const today = inject<Ref<dayjs.Dayjs>>("today");
const disablePast = inject<boolean>("disablePast");
const disableFuture = inject<boolean>("disableFuture");

const days = computed(() => {
  const list: Day[][] = [];
  for (let i = 0; i < 6; i++) {
    list.push([]);
    let startingWeekDate;

    if (startDay === 0) {
      startingWeekDate = activeDate.value
        .startOf("month")
        .add(i, "week")
        .startOf("week");
    } else if (startDay === 1) {
      startingWeekDate = activeDate.value
        .startOf("month")
        .add(i, "week")
        .startOf("isoWeek");
    }

    for (let j = 0; j < 7; j++) {
      const dayJS = startingWeekDate.add(j, "day");
      list[i].push({
        current: dayJS.isToday(),
        dayJS: dayJS,
        disabled: dayJS.month() !== activeDate.value.month(),
        focused: dayJS.date() === focusedDate.value.date(),
        label: dayJS.date(),
        selectable: isDateSelectable(dayJS) && !filter(dayJS),
        selected: dayJS.isSame(selectedDate.value, "day"),
        ariaLabel: dayJS.toString(),
      });
    }
  }
  return list;
});

const handleSelectDay = (day: Day) => {
  if (day.disabled) {
    return;
  }

  selectDay(day);
};

const isDateSelectable = (date: dayjs.Dayjs) => {
  if (disablePast) {
    if (startDate.value) {
      return date.isSameOrAfter(startDate.value);
    } else {
      return date.add(1, "day").isSameOrAfter(today.value);
    }
  } else if (disableFuture) {
    return date.isSameOrBefore(startDate.value || today.value);
  } else if (minDate.value && maxDate.value) {
    return (
      date.isSameOrAfter(minDate.value) && date.isSameOrBefore(maxDate.value)
    );
  } else if (minDate.value) {
    return date.isSameOrAfter(minDate.value);
  } else if (maxDate.value) {
    return date.isSameOrBefore(maxDate.value);
  }

  return true;
};

// keyboard accessibility -------------------------------------
const handleDayUp = inject<() => void>("handleDayUp");
const handleDayDown = inject<() => void>("handleDayDown");
const handleDayLeft = inject<() => void>("handleDayLeft");
const handleDayRight = inject<() => void>("handleDayRight");
const handleDayHome = inject<() => void>("handleDayHome");
const handleDayEnd = inject<() => void>("handleDayEnd");
const handleDayPageUp = inject<() => void>("handleDayPageUp");
const handleDayPageDown = inject<() => void>("handleDayPageDown");
const isDatepickerViewFocused = ref(false);

const handleEnter = () => {
  if (isDateSelectable(focusedDate.value) && !filter(focusedDate.value)) {
    const dateObj: Day = {};
    dateObj.dayJS = focusedDate.value;
    selectDay(dateObj);
  }
};
const handleKeydown = (event: KeyboardEvent) => {
  switch (event.key) {
    case "Home":
      handleDayHome();
      break;
    case "End":
      handleDayEnd();
      break;
    case "PageUp":
      handleDayPageUp();
      break;
    case "PageDown":
      handleDayPageDown();
      break;
    default:
      break;
  }
};
const datepickerDateControlsRef = ref(null);
const handleTab = (event: Event) => {
  if (props.inline) {
    event.preventDefault();
    const changeButtonEl = datepickerDateControlsRef.value.querySelector(
      ".datepicker-view-change-button"
    );
    changeButtonEl.focus();
  }
};
const datepickerViewRef = ref(null);
onMounted(() => {
  setTimeout(() => {
    datepickerViewRef.value.focus();
  }, 100);
});
</script>
