<template>
  <component :is="tag" ref="stepRef" :class="className">
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBStepperStep",
};
</script>

<script setup lang="ts">
import {
  computed,
  onMounted,
  ref,
  inject,
  watch,
  provide,
  toRefs,
  onUnmounted,
  nextTick,
} from "vue";
import type { ComputedRef } from "vue";
import { on, off } from "../../../utils/MDBEventHandlers";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

const props = defineProps({
  tag: {
    type: String,
    default: "li",
  },
  optional: {
    type: Boolean,
    default: false,
  },
  dynamic: Boolean,
});

const stepRef = ref(null);

const className = computed(() => {
  return [
    "stepper-step",
    isActive.value && "stepper-active",
    isCompleted.value && "stepper-completed",
    isValidated.value && "was-validated",
    isVisited.value && "stepper-disabled",
  ];
});

// ---------- Managing Current Step -------------
const { steps, activeStep } = toRefs(inject<State>("state", null));
const addStep = inject<() => number>("addStep", null);
const addStepRef = inject<(ref: HTMLElement, index: number) => void>(
  "addStepRef",
  null
);
const stepIndex = addStep();
provide("stepIndex", stepIndex);

const isActive = computed(() => {
  return activeStep.value === stepIndex;
});
const isCompleted = computed(() => {
  return steps.value[stepIndex].completed;
});
const isVisited = computed(() => {
  return steps.value[stepIndex].visited;
});

provide("optional", props.optional);

watch(
  () => isActive.value,
  (cur) => {
    if (cur) {
      nextTick(() => setHeight());
    }
  }
);

// ---------- Validation -------------
const isValidated = computed(() => {
  return steps.value[stepIndex].validated;
});

watch(
  () => isValidated.value,
  (cur) => {
    if (cur) {
      setTimeout(setHeight, 200);
    }
  }
);

// ---------- Stepper Height -------------

const vertical = inject<ComputedRef<boolean> | false>("vertical", false);
const mobile = inject<ComputedRef<boolean> | false>("mobile", false);

if (mobile) {
  watch(
    () => mobile.value,
    (cur) => {
      if (cur) {
        setTimeout(setHeight, 250);
      }
    }
  );
}

if (vertical) {
  watch(
    () => vertical.value,
    (cur) => {
      if (cur) {
        setHeight();
      }
    }
  );
}

const getParentElement = (element: HTMLElement, selector: string) => {
  let isParentOk = false;
  let checkElement = element;
  while (!isParentOk && checkElement !== document.body) {
    const parentElement = checkElement.parentNode as HTMLElement;
    if (parentElement.classList.contains(selector)) {
      isParentOk = true;
    }
    checkElement = parentElement;
  }

  return checkElement;
};

const setStepperHeight = inject<(height: number) => void>("setStepperHeight");
const setHeight = () => {
  if (!stepRef.value) return;

  const element = stepRef.value as HTMLElement;
  const stepContent = element.querySelector(".stepper-content") as HTMLElement;
  const stepFooter = getParentElement(element, "stepper").querySelector(
    `.stepper-mobile-footer`
  ) as HTMLElement;
  const contentStyle = stepContent && getComputedStyle(stepContent);
  const footerStyle = stepFooter ? getComputedStyle(stepFooter) : "";
  let stepHead: HTMLElement;

  if (mobile && mobile.value) {
    stepHead = getParentElement(element, "stepper").querySelector(
      ".stepper-mobile-head"
    );
  } else {
    stepHead = element.querySelector(".stepper-head");
  }

  const headStyle = stepHead && getComputedStyle(stepHead);

  if (stepContent && contentStyle) {
    const stepContentHeight =
      stepContent.offsetHeight +
      parseFloat(contentStyle.marginTop) +
      parseFloat(contentStyle.marginBottom);

    const stepHeadHeight =
      stepHead.offsetHeight +
      parseFloat(headStyle.marginTop) +
      parseFloat(headStyle.marginBottom);

    const stepFooterHeight = footerStyle
      ? stepFooter.offsetHeight +
        parseFloat(footerStyle.marginTop) +
        parseFloat(footerStyle.marginBottom)
      : 0;

    setStepperHeight(stepHeadHeight + stepContentHeight + stepFooterHeight);
  }
};

// ---------- Slot Observer -------------
const dynamic = ref(props.dynamic);
const isVertical = vertical && vertical.value;

const handleSlotChanges = (mutationsList: MutationRecord[]) => {
  if (!isActive.value || !props.dynamic || isVertical) {
    return;
  }
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      setHeight();
    }
  }
};

const config = { childList: true, subtree: true };
const observer = new MutationObserver(handleSlotChanges);

// ---------- Lifecycle Hooks -------------
onMounted(() => {
  addStepRef(stepRef.value, stepIndex);

  if (activeStep.value === stepIndex && vertical && !vertical.value) {
    setHeight();
  }

  on(window, "resize", setHeight);
  if (stepRef.value && dynamic.value && !isVertical) {
    observer.observe(stepRef.value as HTMLElement, config);
  }
});

onUnmounted(() => {
  off(window, "resize", setHeight);

  observer.disconnect();
});
</script>
