<template>
  <MDBScrollbar
    v-if="scrollContainer"
    :height="scrollElementHeight"
    suppressScrollX
  >
    <component :class="className" :is="tag" v-bind="$attrs" ref="sidenavMenu">
      <div v-if="filter" class="mt-2 mx-2">
        <MDBInput
          v-model="search"
          role="searchbox"
          type="text"
          label="Search"
        />
      </div>
      <slot />
    </component>
  </MDBScrollbar>
  <component
    v-else
    :class="className"
    :is="tag"
    v-bind="$attrs"
    ref="sidenavMenu"
  >
    <div v-if="filter" class="mt-2 mx-2">
      <MDBInput v-model="search" role="searchbox" type="text" label="Search" />
    </div>
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBSideNavMenu",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, onMounted, provide, reactive, ref, watch } from "vue";
import MDBScrollbar from "../../../../src/components/pro/methods/MDBScrollbar.vue";
import { MDBInput } from "../../../index.free";

const props = defineProps({
  tag: {
    type: String,
    default: "ul",
  },
  classes: String,
  accordion: {
    type: Boolean,
    default: false,
  },
  scrollContainer: Boolean,
  filter: {
    type: Boolean,
    default: false,
  },
});

const className = computed(() => {
  return ["sidenav-menu", props.classes];
});

const accordionState = reactive({
  itemsCount: 0,
  active: null,
});

const incrementAccordionItemsCount = () => {
  accordionState.itemsCount++;
  return accordionState.itemsCount;
};

const setAccordionActiveItem = (item: number) => {
  accordionState.active = item;
};

provide("accordionState", props.accordion ? accordionState : null);
provide("incrementAccordionItemsCount", incrementAccordionItemsCount);
provide("setAccordionActiveItem", setAccordionActiveItem);

onMounted(() => {
  if (props.scrollContainer) {
    setupScrollContent();
  }
});

const sidenavMenu = ref<HTMLElement | string>("sidenavMenu");
const scrollElementHeight = ref("100%");

const setupScrollContent = () => {
  const scrollElement = sidenavMenu.value as HTMLElement;
  const siblings = Array.from(
    scrollElement.parentNode.parentNode.children
  ).filter((el) => el !== scrollElement.parentNode);

  const siblingsHeight = siblings.reduce((a, b) => {
    return a + b.clientHeight;
  }, 0);

  scrollElementHeight.value = `calc(100% - ${siblingsHeight}px)`;
};

// filtering
const search = ref("");
const sidenavItems = ref([]);
const handleFilter = () => {
  // get sidenav items for filtering
  sidenavItems.value = [
    ...(sidenavMenu.value as HTMLElement).querySelectorAll(".sidenav-item"),
  ];

  sidenavItems.value.forEach((el) => {
    el.style.display = "block";
  });

  if (search.value) {
    sidenavItems.value.forEach((el) => {
      const elText = el.textContent.trim().toLowerCase();
      const isIncluded = elText.includes(search.value.toLowerCase());
      if (!isIncluded) {
        el.style.display = "none";
      }
    });
  }
};

watch(
  () => search.value,
  () => {
    handleFilter();
  }
);
</script>
