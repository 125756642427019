<template>
  <component
    :is="tag"
    ref="stepperRef"
    :class="wrapperClassName"
    :style="{ height: isVertical ? null : `${stepperHeight}px` }"
  >
    <div v-if="isMobile" class="stepper-mobile-head bg-light">
      {{ props.stepTxt }} {{ state.activeStep + 1 }} {{ props.stepOfTxt }}
      {{ state.count }}
    </div>
    <slot />
    <div v-if="isMobile" class="stepper-mobile-footer bg-light">
      <div class="stepper-back-btn">
        <button type="button" class="btn btn-link" @click="prevStep">
          <i class="fas fa-chevron-left"></i>
          {{ props.backBtn }}
        </button>
      </div>

      <div v-if="mobileProgress" class="stepper-mobile-progress gray-500">
        <div
          class="stepper-mobile-progress-bar bg-primary"
          :style="{ width: `${((state.activeStep + 1) / state.count) * 100}%` }"
        ></div>
      </div>

      <div class="stepper-next-btn">
        <button
          type="button"
          class="btn btn-link ripple-surface"
          @click="nextStep"
        >
          {{ props.nextBtn }}
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBStepper",
};
</script>

<script setup lang="ts">
import { computed, ref, reactive, provide, onMounted } from "vue";
import { validateStep } from "./stepper-validation";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

const props = defineProps({
  tag: {
    type: String,
    default: "ul",
  },
  activeStepIndex: {
    type: Number,
    default: 1,
  },
  linear: Boolean,
  noEditable: Boolean,
  vertical: Boolean,
  mobile: Boolean,
  mobileProgress: Boolean,
  verticalBreakpoint: Number,
  mobileBreakpoint: Number,
  disableStepperHeadClick: Boolean,
  nextBtn: {
    type: String,
    default: "NEXT",
  },
  backBtn: {
    type: String,
    default: "BACK",
  },
  stepTxt: {
    type: String,
    default: "step",
  },
  stepOfTxt: {
    type: String,
    default: "of",
  },
  animation: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["on-invalid", "on-valid", "on-change-step"]);

const stepperRef = ref(null);
const wrapperClassName = computed(() => {
  return [
    "stepper",
    isVertical.value && "stepper-vertical",
    isMobile.value && "stepper-mobile",
    props.mobileProgress && "stepper-progress-bar",
  ];
});

// ---------- Managing Steps -------------
const state = reactive<State>({
  steps: [],
  count: 0,
  activeStep: props.activeStepIndex - 1,
  prevStep: undefined,
});

const addStep = () => {
  const newStep = {
    index: state.count,
    completed: false,
    ref: null,
    validated: false,
    valid: false,
    visited: false,
  };
  state.steps.push(newStep);
  state.count++;
  return newStep.index;
};

const addStepRef = (ref: HTMLElement, index: number) => {
  state.steps[index].ref = ref;
};

const setActiveStep = (index: number) => {
  if (checkIndex(index)) {
    state.activeStep = index;
    emitEvent(index, "on-valid");
  }
};

const setPrevStep = (index: number) => {
  if (checkIndex(index)) {
    state.prevStep = index;
    setStepAttribute(index, "completed");
    emitEvent(index, "on-change-step");
  }
};

const setStepAttribute = (index: number, attribute: string) => {
  if (checkIndex(index)) {
    state.steps[index][attribute] = true;
  }
};

const checkIndex = (index: number) => {
  return state.steps.findIndex((step) => step.index === index) > -1;
};

const verticalState = ref(props.vertical);
const isVertical = computed(() => {
  return props.vertical || verticalState.value;
});

const mobileState = ref(props.mobile);
const isMobile = computed(() => {
  return props.mobile || mobileState.value;
});

provide("state", state);
provide("addStep", addStep);
provide("addStepRef", addStepRef);
provide("setActiveStep", setActiveStep);
provide("setPrevStep", setPrevStep);
provide("linear", props.linear);
provide("mobile", isMobile);
provide("vertical", isVertical);
provide("noEditable", props.noEditable);
provide("setStepAttribute", setStepAttribute);
provide("disableStepperHeadClick", props.disableStepperHeadClick);
provide("showAnimation", props.animation);

// ---------- Set Stepper Height -------------
const stepperHeight = ref(null);
const setStepperHeight = (height: number) => {
  stepperHeight.value = height;
};

provide("setStepperHeight", setStepperHeight);

// ---------- Breakpoints -------------

const checkBreakpoints = () => {
  const shouldBeHorizontal = props.verticalBreakpoint < window.innerWidth;
  const shouldBeVertical = props.verticalBreakpoint > window.innerWidth;
  const shouldBeMobile = props.mobileBreakpoint > window.innerWidth;

  if (shouldBeHorizontal) {
    mobileState.value = false;
    verticalState.value = false;
  } else if (shouldBeVertical && !shouldBeMobile) {
    mobileState.value = false;
    verticalState.value = true;
  } else if (shouldBeMobile) {
    mobileState.value = true;
    verticalState.value = false;
  }
};

// ---------- Validation -------------
const stepValidation = (
  activeStep: number,
  steps: Step[],
  stepIndex: number
) => {
  if (props.linear) {
    setStepAttribute(activeStep, "validated");
    const isValid = validateStep(activeStep, steps, stepIndex, props.linear);
    if (!isValid) {
      emitEvent(activeStep, "on-invalid");
    }

    return isValid;
  }

  return true;
};

// ---------- Events -------------

const emitEvent = (
  stepIndex: number,
  type: "on-invalid" | "on-valid" | "on-change-step"
) => {
  if (checkIndex(stepIndex)) {
    emit(type, state.steps[stepIndex].ref);
  }
};

provide("emitEvent", emitEvent);

// ---------- Public Methods -------------

const nextStep = () => {
  if (stepValidation(state.activeStep, state.steps, state.activeStep + 1)) {
    checkIndex(state.activeStep + 1) && setPrevStep(state.activeStep);
    setActiveStep(state.activeStep + 1);
  }
};
const prevStep = () => {
  checkIndex(state.activeStep - 1) && setPrevStep(state.activeStep);
  setActiveStep(state.activeStep - 1);
};
const changeStep = (index: number) => {
  const validated =
    index - 1 > state.activeStep
      ? true
      : stepValidation(state.activeStep, state.steps, index - 1);
  if (validated) {
    if (index - 1 !== state.activeStep) {
      setPrevStep(state.activeStep);
      setActiveStep(index - 1);
    }
  }
};

onMounted(() => {
  if (props.mobileBreakpoint || props.verticalBreakpoint) {
    checkBreakpoints();
    window.addEventListener("resize", checkBreakpoints);
  }
});

defineExpose({
  changeStep,
  nextStep,
  prevStep,
});
</script>
