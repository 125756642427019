<template>
  <component
    :is="tag"
    class="stepper-head"
    :data-mdb-content="optional ? 'Optional' : null"
    @[clickEvent]="setCurrentActive"
  >
    <span v-if="icon" class="stepper-head-icon">{{ icon }}</span>
    <span v-else class="stepper-head-icon"><slot name="icon" /></span>
    <span class="stepper-head-text"><slot /></span>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBStepperHead",
};
</script>

<script setup lang="ts">
import { computed, inject, toRefs } from "vue";
import { validateStep } from "./stepper-validation";

interface Step {
  completed: boolean;
  index: number;
  ref: HTMLElement | null;
  valid: boolean;
  validated: boolean;
  visited: boolean;
}

interface State {
  steps: Step[];
  count: number;
  activeStep: number;
  prevStep: number;
}

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  icon: String,
});

const { activeStep, steps } = toRefs(inject<State>("state"));
const stepIndex = inject<number>("stepIndex", null);
const setActiveStep = inject<(index: number) => void>("setActiveStep", null);
const setPrevStep = inject<(index: number) => void>("setPrevStep", null);
const linear = inject<boolean>("linear", null);
const noEditable = inject<boolean>("noEditable", null);
const setStepAttribute = inject<(index: number, attribute: string) => void>(
  "setStepAttribute",
  null
);
const optional = inject<boolean>("optional", null);
const emitEvent = inject<
  (
    stepIndex: number,
    type: "on-invalid" | "on-valid" | "on-change-step"
  ) => void
>("emitEvent", null);
const disableStepperHeadClick = inject<boolean>(
  "disableStepperHeadClick",
  null
);

const setCurrentActive = () => {
  if (linear && stepIndex > activeStep.value) {
    setStepAttribute(activeStep.value, "validated");
    const isValid = validateStep(
      activeStep.value,
      steps.value,
      stepIndex,
      linear
    );
    if (!isValid) {
      emitEvent(activeStep.value, "on-invalid");
      return;
    }
  }

  if (noEditable) {
    setStepAttribute(activeStep.value, "visited");
    const isVisited = steps.value[stepIndex].visited;

    if (isVisited) {
      return;
    }
  }
  stepIndex !== activeStep.value && setPrevStep(activeStep.value);
  setActiveStep(stepIndex);
};

const clickEvent = computed(() => {
  return disableStepperHeadClick ? null : "click";
});
</script>
