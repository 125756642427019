<template>
  <div ref="datepickerMainRef" class="datepicker-main">
    <MDBDatepickerDayView v-if="view === 'day'" :inline="inline" />
    <MDBDatepickerYearView v-if="view === 'year'" />
    <MDBDatepickerMonthView v-if="view === 'month'" />

    <div v-if="!inline" class="datepicker-footer">
      <button
        v-if="!removeClearBtn"
        class="datepicker-footer-btn datepicker-clear-btn"
        :aria-label="clearBtnLabel"
        @click="clear"
      >
        {{ clearBtnText }}
      </button>
      <button
        v-if="!removeCancelBtn"
        class="datepicker-footer-btn datepicker-cancel-btn"
        :aria-label="cancelBtnLabel"
        @click="cancel"
      >
        {{ cancelBtnText }}
      </button>
      <button
        v-if="!removeOkBtn"
        class="datepicker-footer-btn datepicker-ok-btn"
        :aria-label="okBtnLabel"
        @click="ok"
        @keydown.tab.prevent="handleTab"
      >
        {{ okBtnText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerMain",
};
</script>

<script setup lang="ts">
import { inject, ref } from "vue";
import type { Ref } from "vue";

import MDBDatepickerDayView from "./MDBDatepickerDayView.vue";
import MDBDatepickerYearView from "./MDBDatepickerYearView.vue";
import MDBDatepickerMonthView from "./MDBDatepickerMonthView.vue";

const props = defineProps({
  inline: Boolean,
});

const ok = inject<() => void>("ok");
const cancel = inject<() => void>("cancel");
const clear = inject<() => void>("clear");
const view = inject<Ref<string>>("view");

const cancelBtnText = inject<string>("cancelBtnText");
const clearBtnText = inject<string>("clearBtnText");
const okBtnText = inject<string>("okBtnText");
const cancelBtnLabel = inject<string>("cancelBtnLabel");
const clearBtnLabel = inject<string>("clearBtnLabel");
const okBtnLabel = inject<string>("okBtnLabel");
const removeClearBtn = inject<boolean>("removeClearBtn");
const removeCancelBtn = inject<boolean>("removeCancelBtn");
const removeOkBtn = inject<boolean>("removeOkBtn");

const datepickerMainRef = ref(null);
const handleTab = () => {
  const changeButtonEl = datepickerMainRef.value.querySelector(
    ".datepicker-view-change-button"
  );
  changeButtonEl.focus();
};
</script>
