<template>
  <component
    :is="tag"
    ref="ratingRef"
    :class="className"
    @mouseleave="setHoveredRating(null, 'leave')"
  >
    <slot />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBRating",
};
</script>

<script setup lang="ts">
import { computed, provide, reactive, ref, watchEffect } from "vue";
const props = defineProps({
  tag: {
    type: String,
    default: "ul",
  },
  modelValue: {
    type: Number,
    default: -1,
  },
  readonly: Boolean,
  events: Boolean,
  dynamic: Boolean,
  classes: String,
});

const emit = defineEmits(["update:modelValue", "item-click", "item-hover"]);

const ratingRef = ref(null);

const className = computed(() => {
  return ["rating", props.classes];
});

const activeItem = ref(props.modelValue);
const setActiveRating = (rating: number, e: Event) => {
  if (!e) {
    return;
  }
  emit("update:modelValue", rating);
  props.events && emit("item-click", e);
};
watchEffect(() => (activeItem.value = props.modelValue));

const hoveredItem = ref<number>(null);
const setHoveredRating = (rating: number, e: Event | string) => {
  if (!e) {
    return;
  }
  hoveredItem.value = rating;
  e !== "leave" && props.events && emit("item-hover", e);
};

const dynamicStyle = reactive({
  icon: "",
  color: "",
});

const setDynamic = (icon: string, color: string) => {
  dynamicStyle.icon = icon;
  dynamicStyle.color = color;
};

provide("activeItem", activeItem);
provide("hoveredItem", hoveredItem);
provide("setActiveRating", setActiveRating);
provide("setHoveredRating", setHoveredRating);
provide("readonly", props.readonly);
provide("dynamic", props.dynamic);
provide("dynamicStyle", dynamicStyle);
provide("setDynamic", setDynamic);
</script>
