<template>
  <component
    :is="tag"
    class="timepicker-clock-wrapper d-flex justify-content-center flex-column align-items-center"
  >
    <MDBTimepickerClock
      v-if="unitsMode === 'hours'"
      v-model="hours"
      v-model:angle="angle"
      :unitsMode="unitsMode"
      :min="1"
      :max="hoursFormat"
      :allowedValues="allowedHours"
      :rotate="30"
      :double="double"
      @input="(e) => updateHoursValue(e)"
      @change="chageUnitsMode('minutes')"
    />
    <MDBTimepickerClock
      v-if="unitsMode === 'minutes'"
      v-model="minutes"
      v-model:angle="angle"
      :unitsMode="unitsMode"
      :min="0"
      :max="59"
      :allowedValues="allowedMinutes"
      @input="(e) => updateMinutesValue(e)"
    />
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerPlate",
};
</script>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import type { ComputedRef, Ref } from "vue";
import MDBTimepickerClock from "./MDBTimepickerClock.vue";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
});

// ------------- TIMEPICKER PROPS INJECTS -------------
const hoursFormat = inject<number>("hoursFormat", null);
const increment = inject<number>("increment", null);

// ------------- STATE MANAGEMENT -------------
const hours = inject<ComputedRef<string | number>>("hours", null);
const minutes = inject<ComputedRef<string>>("minutes", null);
const unitsMode = inject<Ref<string>>("unitsMode", null);
const updateMinutesValue = inject<(value: number) => void>(
  "updateMinutesValue",
  null
);
const updateHoursValue = inject<
  (
    delta: number,
    currentValue?: number,
    recursion?: undefined | boolean
  ) => number
>("updateHoursValue", null);
const chageUnitsMode = inject<(value: string) => void>("chageUnitsMode", null);

const double = computed(() => {
  return hoursFormat === 24;
});

const angle = ref(360);

// ------------- VALIDATION -------------
const allowedHours = inject<(value: number) => number | boolean>(
  "allowedHours",
  null
);
const allowedMinutes = inject<(value: number) => boolean>(
  "allowedMinutes",
  null
);
</script>
