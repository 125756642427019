<template>
  <label v-if="label" :class="labelClassName" :for="uid">{{ label }}</label>
  <component :is="tag" :class="wrapperClassName">
    <input
      :id="uid"
      type="range"
      :class="inputClassName"
      :value="inputValue"
      :min="minValue"
      :max="maxValue"
      v-bind="$attrs"
      @input="handleInput"
      @mousedown="toggleThumb('first', true), $emit('start')"
      @touchstart="toggleThumb('first', true), $emit('start')"
      @mouseup="toggleThumb('first', false), $emit('end')"
      @touchend="toggleThumb('first', false), $emit('end')"
      @keydown.left="$emit('slide')"
      @keydown.right="$emit('slide')"
      @keydown.up="$emit('slide')"
      @keydown.down="$emit('slide')"
      @focus="toggleThumb('first', true)"
      @blur="toggleThumb('first', false)"
    />
    <span
      v-if="thumb"
      :class="thumbClassName"
      :style="{ left: thumbsLeftPosition['first'] }"
    >
      <span class="thumb-value">{{ inputValue }}</span>
    </span>
    <input
      v-if="!props.oneRange"
      :id="secondUid"
      type="range"
      :class="secondInputClassName"
      :value="secondInputValue"
      :min="minValue"
      :max="maxValue"
      v-bind="$attrs"
      @input="handleSecondInput"
      @mousedown="toggleThumb('second', true), $emit('start')"
      @touchstart="toggleThumb('second', true), $emit('start')"
      @mouseup="toggleThumb('second', false), $emit('end')"
      @touchend="toggleThumb('second', false), $emit('end')"
      @keydown.left="$emit('slide')"
      @keydown.right="$emit('slide')"
      @keydown.up="$emit('slide')"
      @keydown.down="$emit('slide')"
      @focus="toggleThumb('second', true)"
      @blur="toggleThumb('second', false)"
      :style="secondInputStyle"
    />
    <span
      v-if="thumb && !props.oneRange"
      :class="secondThumbClassName"
      :style="{ left: thumbsLeftPosition['second'] }"
    >
      <span class="thumb-value">{{ secondInputValue }}</span>
    </span>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBMultiRange",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, ref, watch, nextTick, reactive, CSSProperties } from "vue";
import { getUID } from "../../../../src/components/utils/getUID";
const props = defineProps({
  id: String,
  secondId: String,
  inputClass: String,
  label: String,
  labelClass: String,
  max: {
    type: Number,
    default: 100,
  },
  min: {
    type: Number,
    default: 0,
  },
  firstValue: {
    type: Number,
    default: 0,
  },
  secondValue: {
    type: Number,
    default: 100,
  },
  tag: {
    type: String,
    default: "div",
  },
  thumb: {
    type: Boolean,
    default: true,
  },
  oneRange: {
    type: Boolean,
    default: false,
  },
  thumbClass: String,
  wrapperClass: String,
});

const emit = defineEmits([
  "update:firstValue",
  "update:secondValue",
  "start",
  "end",
  "slide",
]);

const inputValue = ref(props.firstValue);
const minValue = ref(props.min);
const maxValue = ref(props.max);
const uid = props.id || getUID("MDBRange-");
const activeThumbs = reactive({ first: false, second: false });

const secondInputValue = ref(props.secondValue);
const secondUid = props.secondId || getUID("MDBRange-");

const wrapperClassName = computed(() => {
  return ["range multi-range-slider", props.wrapperClass];
});
const inputClassName = computed(() => {
  return ["form-range", props.inputClass];
});
const secondInputClassName = computed(() => {
  return ["form-range multi-range-input", props.inputClass];
});
const labelClassName = computed(() => {
  return ["form-label", props.labelClass];
});
const thumbClassName = computed(() => {
  return ["thumb", activeThumbs["first"] && "thumb-active", props.thumbClass];
});
const secondThumbClassName = computed(() => {
  return ["thumb", activeThumbs["second"] && "thumb-active", props.thumbClass];
});
const thumbsLeftPosition = reactive({ first: 0, second: 0 });

const secondInputStyle = computed<CSSProperties>(() => {
  return {
    position: "absolute",
    left: 0,
  };
});

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const value = parseFloat(target.value);
  if (value > secondInputValue.value) {
    target.valueAsNumber = secondInputValue.value;
    return;
  }

  inputValue.value = value;
  emit("update:firstValue", inputValue.value);

  setThumbPosition("first");
};

const handleSecondInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const value = parseFloat(target.value);
  if (value < inputValue.value) {
    target.valueAsNumber = inputValue.value;
    return;
  }
  secondInputValue.value = value;
  emit("update:secondValue", secondInputValue.value);

  setThumbPosition("second");
};

const toggleThumb = (thumb: string, state: boolean) => {
  activeThumbs[thumb] = state;
};

const setThumbPosition = (thumb: string) => {
  let left: number;

  const minV =
    typeof minValue.value === "string"
      ? parseFloat(minValue.value)
      : minValue.value;
  const maxV =
    typeof maxValue.value === "string"
      ? parseFloat(maxValue.value)
      : maxValue.value;

  if (thumb === "first") {
    const iV =
      typeof inputValue.value === "string"
        ? parseFloat(inputValue.value)
        : inputValue.value;

    left = ((iV - minV) * 100) / (maxV - minV);
  } else if (thumb === "second") {
    const siV =
      typeof secondInputValue.value === "string"
        ? parseFloat(secondInputValue.value)
        : secondInputValue.value;

    left = ((siV - minV) * 100) / (maxV - minV);
  }

  thumbsLeftPosition[thumb] = `calc(${left}% + (${8 - left * 0.15}px))`;
};

nextTick(() => {
  setThumbPosition("first");
  setThumbPosition("second");
});

watch(
  () => props.firstValue,
  (value) => {
    inputValue.value = value;
    setThumbPosition("first");
  }
);

watch(
  () => props.secondValue,
  (value) => {
    secondInputValue.value = value;
    setThumbPosition("second");
  }
);
</script>

<style scoped></style>
