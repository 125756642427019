<template>
  <component
    :is="tag"
    ref="modalRef"
    class="timepicker-modal"
    tabindex="-1"
    role="dialog"
  >
    <div
      ref="wrapperRef"
      v-mdb-click-outside="handleCancelModal"
      v-mdb-focus-trap
      :class="wrapperClass"
      style="opacity: 1"
    >
      <div
        class="d-flex align-items-center justify-content-center flex-column shadow timepicker-container"
      >
        <div
          class="d-flex flex-column timepicker-elements justify-content-around timepicker-elements-inline"
        >
          <MDBTimepickerHeader />
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
export default {
  name: "MDBTimepickerInline",
};
</script>

<script setup lang="ts">
import { computed, inject, onMounted, ref, onBeforeUnmount } from "vue";
import MDBTimepickerHeader from "./MDBTimepickerHeader.vue";
import vMdbClickOutside from "../../../../../src/directives/free/mdbClickOutside";
import MDBPopper from "../../../../../src/components/utils/MDBPopper";
import vMdbFocusTrap from "../../../../../src/directives/free/mdbFocusTrap";

defineProps({
  modelvalue: Boolean,
  tag: {
    type: String,
    default: "div",
  },
});

const emit = defineEmits(["cancel"]);

// ------------- REFS -------------
const modalRef = ref<HTMLElement | null>(null);
const wrapperRef = ref<HTMLElement | null>(null);

// ------------- STYLING -------------
const wrapperClass = computed(() => {
  return [
    "timepicker-wrapper h-100 d-flex align-items-center justify-content-center flex-column timepicker-wrapper-inline",
  ];
});

// ------------- POPPER METHODS -------------
const { setPopper, openPopper, closePopper } = MDBPopper();
const parentId = inject("inputId", null);
const dateTimepicker = inject("dateTimepicker", null);

const fallbackPlacements = ["top", "right", "bottom", "left"];

const popperSetup = () => {
  let triggerEl: HTMLElement;
  if (parentId) {
    triggerEl = document.getElementById(parentId);
  }
  if (dateTimepicker) {
    triggerEl = dateTimepicker.value.inputRef;
  }
  const popperEl = modalRef.value;

  const config = {
    placement: `bottom-start`,
    eventsEnabled: true,
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements,
        },
      },
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParent",
        },
      },
    ],
  };

  setPopper(triggerEl, popperEl, config);

  openPopper();
};

// ------------- OPENING/CLOSING METHODS -------------
const handleCancelClick = inject<() => void>("handleCancelClick", null);

// prevent handleClose from firing on modal show
const debounce = ref(false);
const handleCancelModal = (e: Event) => {
  const target = e.target as HTMLElement;
  if (!target.closest(".timepicker-modal")) {
    if (debounce.value) {
      emit("cancel");
    }
  }
};

// ------------- LIFECYCLE HOOKS -------------
onMounted(() => {
  popperSetup();
  setTimeout(() => {
    debounce.value = true;
  }, 500);
});

onBeforeUnmount(() => {
  closePopper();
});
</script>
