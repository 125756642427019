<template>
  <teleport :to="container">
    <transition-group
      name="datepicker-fade"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <div v-if="isActive" class="datepicker-backdrop"></div>
      <div
        v-if="isActive"
        v-mdb-click-outside="handleClickOutside"
        class="datepicker-modal-container"
      >
        <MDBDatepickerHeader>
          <template
            #header="{ selected, headerWeekday, headerMonth, headerMonthday }"
          >
            <slot
              name="header"
              :headerWeekday="headerWeekday"
              :headerMonth="headerMonth"
              :headerMonthday="headerMonthday"
              :selected="selected"
            ></slot>
          </template>
        </MDBDatepickerHeader>

        <MDBDatepickerMain />
      </div>
    </transition-group>
  </teleport>
</template>

<script lang="ts">
export default {
  name: "MDBDatepickerModal",
};
</script>

<script setup lang="ts">
import { ref, watch, inject } from "vue";
import vMdbClickOutside from "../../../../../src/directives/free/mdbClickOutside";
import { useScrollbarWidth } from "../../../../../src/components/utils/useScrollbarWidth";
import MDBDatepickerHeader from "./MDBDatepickerHeader.vue";
import MDBDatepickerMain from "./MDBDatepickerMain.vue";

const props = defineProps({
  modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue", "cancel"]);

const { beforeEnter, afterLeave } = useScrollbarWidth();
const isActive = ref(props.modelValue);
const container = inject<string>("container", "body");

const handleClickOutside = () => {
  if (!isActive.value || debounce.value) return;
  isActive.value = false;

  emit("cancel");
  emit("update:modelValue", false);
};

const debounce = ref(true);
watch(
  () => props.modelValue,
  (value) => {
    isActive.value = value;
    setTimeout(() => {
      debounce.value = value;
      setTimeout(() => (debounce.value = !value), 100);
    }, 10);
  }
);
</script>
